import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialVk = ({ color }: Props) => {
  const theme = useTheme()
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.283 12.665c1.292 1.2 1.559 1.775 1.6 1.85.534.884-.583.95-.583.95l-2.15.034s-.467.091-1.067-.325c-.808-.55-1.583-1.992-2.158-1.8-.592.191-.575 1.483-.575 1.483s0 .183-.133.325c-.15.158-.442.1-.442.1h-.958s-2.125.217-3.984-1.725C3.792 11.44 1.992 7.274 1.992 7.274s-.1-.25.008-.392c.125-.133.475-.133.475-.133h2.3s.225 0 .367.133c.125.092.2.283.2.283s.366.925.866 1.775c.959 1.659 1.4 2.017 1.709 1.842.5-.258.358-2.342.358-2.342s.008-.758-.242-1.091c-.191-.259-.558-.342-.716-.359-.134-.016.083-.316.358-.458.417-.2 1.142-.2 2.008-.2.675 0 .867.05 1.125.108.8.192.525.934.525 2.7 0 .575-.083 1.359.309 1.634.175.116.608.016 1.691-1.809.5-.866.884-1.891.884-1.891s.083-.175.208-.259c.133-.066.317-.066.317-.066h2.416s.734-.084.85.241c.125.342-.275 1.15-1.266 2.459-1.617 2.166-1.8 1.966-.459 3.216Z"
        fill={color ?? theme.colors.black.total}
      />
    </svg>
  )
}
