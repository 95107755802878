import * as React from 'react'

export const Frontend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.332 9.333H11.665a2.333 2.333 0 00-2.333 2.333v32.667a2.333 2.333 0 002.333 2.333h32.667a2.333 2.333 0 002.333-2.333V11.666a2.333 2.333 0 00-2.333-2.333zM9.332 18.667h37.333M18.668 9.333v9.333"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
