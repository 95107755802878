import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const City: React.FC<Props> = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M15 23h-2v-2h2v2zm4-2h-2v2h2v-2zm-4-4h-2v2h2v-2zm-8 4H5v2h2v-2zm0-4H5v2h2v-2zm12 0h-2v2h2v-2zm-4-4h-2v2h2v-2zm4 0h-2v2h2v-2zm2-4a2 2 0 012 2v12h-2V11H11v12H9v-8H3v8H1v-8a2 2 0 012-2h6v-2a2 2 0 012-2V7a2 2 0 012-2h2V1h2v4h2a2 2 0 012 2v2zm-2 0V7h-6v2h6z"
        fill={color ? color : theme.colors.black.primary}
      />
    </svg>
  )
}
