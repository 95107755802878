import * as React from 'react'

export const Design = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 49a21.001 21.001 0 010-42c5.57 0 10.911 1.967 14.85 5.467 3.938 3.501 6.15 8.249 6.15 13.2 0 2.475-1.106 4.849-3.075 6.6C43.955 34.016 41.285 35 38.5 35h-5.833a4.666 4.666 0 00-2.334 8.75A3.033 3.033 0 0128 49"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.499 25.666a1.167 1.167 0 100-2.333 1.167 1.167 0 000 2.333zM27.999 18.666a1.167 1.167 0 100-2.333 1.167 1.167 0 000 2.333zM38.499 25.666a1.167 1.167 0 100-2.333 1.167 1.167 0 000 2.333z"
        fill="#858585"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
