import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { Body } from 'src/ui/typography'
import { Loader } from 'src/ui/atoms'

type TColors = {
  [key: string]: {
    default: string
    hover: string
    active: string
    disabled: string
  }
}

const Container = styled.button<{
  colors: TColors
  variant: 'primary' | 'accent'
  disabled?: boolean
}>`
  min-height: 56px;
  padding: 16px;
  min-width: 192px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ colors, variant }) => colors[variant].default};

  transition: background-color 0.3s;

  border-radius: 8px;

  &:focus {
    outline: none;
    background-color: ${({ colors, variant }) => colors[variant].hover};
  }
  &:hover {
    background-color: ${({ colors, variant }) => colors[variant].hover};
  }
  &:active {
    background-color: ${({ colors, variant }) => colors[variant].active};
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    background-color: ${({ colors, variant }) => 'rgba(55, 55, 55, 0.3)'};
  }
`

type Props = {
  variant?: 'primary' | 'accent'
  children: JSX.Element | string
  loading?: boolean
  disabled?: boolean
  className?: string
  onClick: (e: React.MouseEvent) => void
}
export const Button: React.FC<Props> = ({
  children,
  variant = 'primary',
  loading,
  disabled,
  onClick,
  className,
}) => {
  const theme = useTheme()
  const colors: TColors = {
    primary: {
      default: theme.colors.brand.primary,
      hover: theme.colors.brand.secondary,
      active: theme.colors.brand.primary,
      disabled: theme.colors.gray.bg,
    },
    accent: {
      default: theme.colors.black.primary,
      hover: theme.colors.black.secondary,
      active: theme.colors.black.primary,
      disabled: theme.colors.gray.bg,
    },
  }
  return (
    <Container
      colors={colors}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {loading ? (
        <Loader
          stroke={
            disabled ? theme.colors.gray.primary : theme.colors.white.primary
          }
        />
      ) : typeof children === 'string' ? (
        <Body as="span" color={theme.colors.white.primary} weight="medium">
          {children}
        </Body>
      ) : (
        children
      )}
    </Container>
  )
}
