import { styled } from 'src/ui/theme'

export const ClippedText = styled.div<{
  $clipped: boolean
  fontSize?: number
  lineHeight?: number
}>`
  ${({ $clipped }) =>
    $clipped &&
    `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  `}
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '24px')};
`
