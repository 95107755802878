import React, { forwardRef } from 'react'
import InputMask, { ReactInputMask } from 'react-input-mask'

import { HBox } from 'src/ui/layout'
import { Body } from 'src/ui/typography'
import { styled, useTheme } from 'src/ui/theme'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Caption = styled(Body)<{ pl?: number; color?: string }>`
  height: 21px;
  color: ${({ theme, color }) => color ?? theme.colors.black.primary};

  ${({ pl }) => pl && `padding-left: ${pl}px`}
`

const Container = styled.div`
  position: relative;
`
const StyledInput = styled(InputMask)<{
  hasLeftAdornment?: boolean
  hasRightAdornment?: boolean
  error?: boolean
  multiline?: boolean
  multilineHeight?: string
  maskChar?: string | null
}>`
  width: 100%;
  min-height: ${({ multiline, multilineHeight }) =>
    multiline ? multilineHeight ?? '180px' : 'initial'};

  padding: 15px 16px;
  border: none;
  background: ${({ theme }) => theme.colors.white.primary};
  color: ${({ theme, error }) =>
    error ? theme.colors.red.error : theme.colors.black.primary};

  border-radius: 4px;
  line-height: 24px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.default};
  outline: none;

  appearance: none;

  transition: box-shadow .3s;

  ${({ error }) =>
    error &&
    `
    box-shadow: 0px 2px 0px 0px #F40D0D;
  `}

  ${({ hasLeftAdornment }) =>
    hasLeftAdornment
      ? `
        padding-left:56px;
  `
      : ''}

  ${({ hasRightAdornment }) =>
    hasRightAdornment
      ? `
        padding-right:56px;
  `
      : ''}

  &:focus,
  &:hover {
    outline: none;
    background: ${({ theme }) => theme.colors.white.primary};
    border-color: ${({ theme }) => theme.colors.brand.primary};
    box-shadow: ${({ theme }) => theme.shadows.primary};
    box-shadow: 0px 2px 0px 0px ${({ error }) =>
      error ? '#F40D0D' : '#4F4FF4'};
  }

  &:disabled {
    opacity: 0.6;
    color: ${({ theme }) => theme.colors.black.secondary};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px ${({ theme }) =>
      theme.colors.white.primary} inset !important;
  }
`

const LeftAdornment = styled.div`
  position: absolute;
  left: 16px;
  top: 8px;
`
const RightAdornment = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
`

type Props = {
  name: string
  mask: string | (string | RegExp)[]
  maskChar?: string | null
  maxLength?: number
  type?: 'text' | 'tel' | 'email'
  title?: string
  value?: string
  placeholder?: string
  leftAdornment?: JSX.Element
  rightAdornment?: JSX.Element
  disabled?: boolean
  error?: string | boolean
  multiline?: boolean
  multilineHeight?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const MaskedInput = forwardRef<ReactInputMask | null, Props>(
  (
    {
      name,
      value,
      title,
      maskChar = null,
      mask,
      placeholder,
      leftAdornment,
      rightAdornment,
      disabled,
      error,
      multiline,
      type,
      multilineHeight,
      maxLength,
      onChange,
      onBlur,
    },
    ref
  ) => {
    const theme = useTheme()

    return (
      <Wrapper>
        {title && (
          <>
            <Caption size="secondary">{title}</Caption>
            <HBox height={8} />
          </>
        )}
        <Container>
          {leftAdornment ? (
            <LeftAdornment>{leftAdornment}</LeftAdornment>
          ) : null}
          {multiline ? (
            <StyledInput
              name={name}
              ref={ref}
              multiline={multiline}
              value={value}
              onChange={onChange}
              disabled={disabled}
              hasLeftAdornment={Boolean(leftAdornment)}
              hasRightAdornment={Boolean(rightAdornment)}
              placeholder={placeholder}
              onBlur={onBlur}
              error={Boolean(error)}
              multilineHeight={multilineHeight}
              maxLength={maxLength}
              mask={mask}
              maskChar={maskChar}
            />
          ) : (
            <StyledInput
              name={name}
              ref={ref}
              multiline={multiline}
              value={value}
              onChange={onChange}
              disabled={disabled}
              hasLeftAdornment={Boolean(leftAdornment)}
              hasRightAdornment={Boolean(rightAdornment)}
              placeholder={placeholder}
              error={Boolean(error)}
              type={type}
              onBlur={onBlur}
              multilineHeight={multilineHeight}
              maxLength={maxLength}
              mask={mask}
              maskChar={maskChar}
            />
          )}

          {rightAdornment ? (
            <RightAdornment>{rightAdornment}</RightAdornment>
          ) : null}
        </Container>
        <Caption color={theme.colors.red.error} size="secondary" pl={16}>
          {error}
        </Caption>
      </Wrapper>
    )
  }
)
