import React from 'react'

import { styled } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Adornments } from 'src/ui/atoms'
import { Video } from 'src/ui/molecules'
import { Label, Header } from 'src/ui/typography'

import { FluidObject } from 'gatsby-image'
import Img from 'gatsby-image/withIEPolyfill'

import VisibilitySensor from 'react-visibility-sensor'
import { useSpring, animated } from 'react-spring'

type Positions = 'bottom-left' | 'top-left' | 'top-right'

const Container = styled.div<{
  height: number
  position: Positions
  width?: number
}>`
  position: relative;

  padding: 0 16px;
  height: ${({ height }) => height}px;
  width: ${({ position, width }) =>
    width
      ? width
      : position === 'top-left' || position === 'bottom-left'
      ? 43.9
      : 56.1}%;

  transform: translateZ(0);

  ${({ position }) => (position === 'top-right' ? 'float: right;' : '')}
`;

const BackgroundContainer = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: 2000px) {
    overflow: visible;
  }
`

const ContentLeft = styled(animated.div)<{ position: Positions }>`
  position: absolute;
  width: 100%;
  bottom: ${({ position }) => (position === 'bottom-left' ? 0 : 'initial')};
  top: ${({ position }) => (position === 'top-left' ? 0 : 'initial')};
  left: ${({ position }) =>
    position === 'top-left' || position === 'bottom-left' ? '-1px' : 'initial'};

  padding-left: ${({ position }) =>
    position === 'bottom-left' || position === 'top-left' ? '0' : '0'};
  padding-right: 36px;

  padding-top: 36px;
  padding-bottom: 40px;

  background: ${({ theme }) => theme.colors.white.primary};
  box-shadow: -100px 0px 0 0 ${({ theme }) => theme.colors.white.primary};

  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.white.primary};
    height: 100%;
    top: 0;

    width: 344px;
    left: -343px;
  }
`

const ContentRight = styled(animated.div)<{ position: Positions }>`
  position: absolute;
  width: 100%;

  top: 0;
  right: 0;

  padding-left: 64px;
  padding-right: 0;

  padding-top: 36px;
  padding-bottom: 40px;

  background: ${({ theme }) => theme.colors.white.primary};
  box-shadow: 100px 0px 0 0 ${({ theme }) => theme.colors.white.primary};

  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.white.primary};
    height: 100%;
    top: 0;

    width: 344px;
    right: -343px;
  }
`

const Content: React.FC<{ position: Positions; style: any }> = ({
  position,
  children,
  style,
}) =>
  position === 'top-right' ? (
    <ContentRight position={position} style={style}>
      {children}
    </ContentRight>
  ) : (
    <ContentLeft position={position} style={style}>
      {children}
    </ContentLeft>
  )

const Image = styled(Img)`
  position: absolute !important;
  object-fit: cover;
  right: 0;
  left: 0;
  top: -4px;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 99%;
  z-index: 0;
`

const Adornment1 = styled.div`
  position: absolute;
  right: -120px;
  top: -80px;
  z-index: 1;
`

type Props = {
  contentPosition?: Positions
  background?: FluidObject
  title?: string
  titleColor?: string
  titleSize?: 'h1' | 'h2'
  titleAs?: 'h1' | 'h2'
  label?: string
  adornment?: boolean
  objectPosition?: string
  height?: number
  contentWidth?: number
  videoId?: string
  onPlay?: () => void
  onPause?: () => void
}

export const HeroDesktopSecondary: React.FC<Props> = ({
  contentPosition,
  title,
  background,
  titleColor,
  titleSize = 'h1',
  titleAs,
  label,
  height = 494,
  contentWidth,
  adornment,
  children,
  objectPosition,
  videoId,
  onPause,
  onPlay,
}) => {
  const [visible, setVisible] = React.useState(false)

  const contentStyles = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateX(-60px)',
  })

  const imageStyles = useSpring({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateX(100px)',
  })

  const handler = (active: boolean) => {
    if (active) {
      setVisible(true)
    }
  }

  return (
    <VisibilitySensor onChange={handler} partialVisibility scrollThrottle={500}>
      <BackgroundContainer>
        {background ? (
          <animated.div style={imageStyles}>
            <Image fluid={background} objectPosition={objectPosition} />
          </animated.div>
        ) : null}

        {videoId ? (
          <Video
            videoId={videoId}
            height={height}
            onPause={() => {
              if (onPause) {
                onPause()
              }
            }}
            onPlay={() => {
              if (onPlay) {
                onPlay()
              }
            }}
            onReady={() => null}
          />
        ) : null}

        {contentPosition ? (
          <Wrapper
            style={{
              height: height,
            }}
          >
            <Container
              height={height}
              position={contentPosition}
              width={contentWidth}
            >
              <Content position={contentPosition} style={contentStyles}>
                {adornment ? (
                  <Adornment1>
                    <Adornments.SquaresArray />
                  </Adornment1>
                ) : null}

                {label ? (
                  <>
                    <Label weight="bold">{label}</Label>
                    <HBox height={8} />
                  </>
                ) : null}
                {title ? (
                  <Header size={titleSize} as={titleAs} color={titleColor}>
                    {title}
                  </Header>
                ) : null}

                {children}
              </Content>
            </Container>
          </Wrapper>
        ) : null}
      </BackgroundContainer>
    </VisibilitySensor>
  )
}
