import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'

//@ts-ignore
import constants from 'hr/constants'

export const client = new ApolloClient({
  uri: `${constants.api_endpoint}`,
  fetch,
})
