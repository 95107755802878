// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-vacancy-tsx": () => import("./../src/templates/vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-benefits-tsx": () => import("./../src/pages/benefits.tsx" /* webpackChunkName: "component---src-pages-benefits-tsx" */),
  "component---src-pages-career-tsx": () => import("./../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-fun-tsx": () => import("./../src/pages/fun.tsx" /* webpackChunkName: "component---src-pages-fun-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kaliningrad-tsx": () => import("./../src/pages/kaliningrad.tsx" /* webpackChunkName: "component---src-pages-kaliningrad-tsx" */),
  "component---src-pages-podcast-tsx": () => import("./../src/pages/podcast.tsx" /* webpackChunkName: "component---src-pages-podcast-tsx" */),
  "component---src-pages-referral-index-tsx": () => import("./../src/pages/referral/index.tsx" /* webpackChunkName: "component---src-pages-referral-index-tsx" */),
  "component---src-pages-referral-referral-policy-tsx": () => import("./../src/pages/referral/referral-policy.tsx" /* webpackChunkName: "component---src-pages-referral-referral-policy-tsx" */),
  "component---src-pages-saint-petersburg-tsx": () => import("./../src/pages/saint-petersburg.tsx" /* webpackChunkName: "component---src-pages-saint-petersburg-tsx" */),
  "component---src-pages-training-tsx": () => import("./../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

