import React from 'react'

import Img, { FluidObject } from 'gatsby-image'

import { styled } from 'src/ui/theme'
import { HBox } from 'src/ui/layout'
import { Header, Body } from 'src/ui/typography'

const Container = styled.div<{ isRight?: boolean }>`
  display: flex;
  flex-flow: ${({ isRight }) => (isRight ? 'row' : 'row-reverse')};
  background: ${({ theme }) => theme.colors.white.primary};
  padding: 0 16px;
`

const ImageSide = styled.div`
  width: 35%;
`
const ContentSide = styled.div<{ isRight?: boolean }>`
  width: 65%;
  padding: 0 16px;
  text-align: ${({ isRight }) => (isRight ? 'right' : 'left')};
`

const Image = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
`

type Props = {
  background?: FluidObject
  align: 'left' | 'right'
  title: string
  description?: string
  titleColor?: string
  titleAs?: 'h1' | 'h2' | 'h3'
}
export const HeroLine: React.FC<Props> = ({
  title,
  description,
  align,
  titleColor,
  titleAs,
  background,
}) => {
  return (
    <Container isRight={align === 'right'}>
      <ContentSide isRight={align === 'right'}>
        <Header size="h4" as={titleAs} color={titleColor}>
          {title}
        </Header>
        <HBox height={8} />
        <Body size="secondary">{description}</Body>
      </ContentSide>
      <ImageSide>
        <Image fluid={background} />
      </ImageSide>
    </Container>
  )
}
