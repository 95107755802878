import React, { ReactNode } from 'react'

import { styled } from 'src/ui/theme'

import { Link } from 'gatsby'

const LinkContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  outline: none;
  text-decoration: none;
  user-select: none;
`
const NativeLink = styled.a`
  text-decoration: none;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  outline: none;
  user-select: none;
`

type Props = {
  onPress?: () => void
  link?: string
  external?: boolean
  style?: React.CSSProperties
  noPointer?: boolean
  id?: string
  stopPropagation?: boolean
  hoverColor?: string
  className?: string
  children: ReactNode
}

export const Touchable = ({
  onPress,
  link,
  external,
  children,
  style,
  noPointer,
  id,
  stopPropagation,
  className,
  hoverColor,
}: Props) => {
  const [hover, setHover] = React.useState(false)

  const coloredChildren =
    hoverColor && hover
      ? React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<any>, {
            color: hoverColor,
          })
        )
      : children

  return link ? (
    external ? (
      <NativeLink
        href={link}
        target="_blank"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={className}
      >
        {coloredChildren}
      </NativeLink>
    ) : (
      <LinkContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        to={link}
        className={className}
      >
        {coloredChildren}
      </LinkContainer>
    )
  ) : (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation()
        }
        if (onPress) {
          onPress()
        }
      }}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.which === 13 && onPress) {
          onPress()
        }
      }}
      style={{ ...style, cursor: noPointer ? 'default' : 'pointer' }}
      id={id}
      className={className}
    >
      {coloredChildren}
    </Container>
  )
}
