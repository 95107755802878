import React from 'react'

type Props = {
  color?: string
}

export const CashMoney: React.FC<Props> = ({ color }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66602 13.3333V50.6666H61.3327V13.3333H2.66602ZM55.9994 45.3333H7.99935V18.6666H55.9994V45.3333ZM31.9993 39.9999C33.5815 39.9999 35.1284 39.5309 36.4439 38.6517C37.7596 37.7727 38.785 36.5231 39.3903 35.0615C39.9959 33.5997 40.1543 31.9911 39.8457 30.4391C39.5369 28.8874 38.7751 27.4618 37.6562 26.3431C36.5375 25.2242 35.1119 24.4623 33.5602 24.1536C32.0082 23.845 30.3996 24.0034 28.9377 24.6089C27.4762 25.2144 26.2267 26.2398 25.3476 27.5554C24.4686 28.8708 23.9993 30.4178 23.9993 31.9999C23.9993 34.1215 24.8422 36.1565 26.3425 37.6567C27.8428 39.157 29.8778 39.9999 31.9993 39.9999ZM31.9993 28.7999C32.6322 28.7999 33.2508 28.9877 33.7772 29.3391C34.3034 29.6909 34.7135 30.1906 34.9559 30.7754C35.198 31.3602 35.2612 32.0034 35.1377 32.6242C35.0143 33.245 34.7098 33.8151 34.262 34.2626C33.8146 34.7103 33.2444 35.0149 32.6236 35.1383C32.0028 35.2618 31.3596 35.1986 30.7748 34.9564C30.19 34.7141 29.6903 34.3039 29.3386 33.7778C28.9871 33.2514 28.7994 32.6327 28.7994 31.9999C28.8001 31.1514 29.1378 30.3381 29.7378 29.7383C30.3375 29.1383 31.1508 28.8007 31.9993 28.7999Z"
      fill={color ?? '#4F4FF4'}
    />
  </svg>
)
