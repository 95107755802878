import React from "react"

export const HhIcon = () => {
    return <svg width="200" height="80" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 200C155.23 200 200 155.23 200 100C200 44.77 155.23 0 100 0C44.77 0 0 44.77 0 100C0 155.23 44.77 200 100 200Z" fill="#7A74E0"/>
        <path d="M55.8801 52.8701V86.8899C61.0601 80.7199 67.2601 77.6101 74.4601 77.6101C78.1701 77.6101 81.49 78.32 84.47 79.72C87.46 81.12 89.6901 82.9101 91.2001 85.0801C92.7201 87.2801 93.74 89.6801 94.29 92.3301C94.84 94.9701 95.1201 99.0699 95.1201 104.64V141.01H79.0301V108.25C79.0301 101.75 78.7401 97.6299 78.1301 95.8799C77.5201 94.1199 76.4401 92.73 74.9001 91.72C73.3501 90.67 71.4201 90.1599 69.0901 90.1599C66.4301 90.1599 64.0301 90.8299 61.9501 92.1499C59.8301 93.4799 58.3001 95.4799 57.3301 98.1399C56.3501 100.81 55.8601 104.74 55.8801 109.96L55.86 141.02H39.79V52.8799H55.8801" fill="white"/>
        <path d="M119.24 52.8701V86.8899C124.42 80.7199 130.62 77.6101 137.82 77.6101C141.51 77.6101 144.86 78.32 147.84 79.72C150.81 81.12 153.05 82.9101 154.55 85.0801C156.07 87.2801 157.1 89.6801 157.64 92.3301C158.19 94.9701 158.47 99.0699 158.47 104.64V141.01H142.4V108.25C142.4 101.75 142.1 97.6299 141.48 95.8799C140.87 94.1199 139.8 92.73 138.24 91.72C136.71 90.67 134.76 90.1599 132.43 90.1599C129.77 90.1599 127.38 90.8299 125.29 92.1499C123.19 93.4799 121.66 95.4799 120.68 98.1399C119.72 100.81 119.23 104.74 119.23 109.96V141.02H103.16V52.8799H119.23" fill="white"/>
    </svg>
    
    
}