import React from 'react'

type Props = {}

export const TortuIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <rect width={32} height={32} rx={4} fill="#fff" fillOpacity={0.9} />
      <path
        d="M9.094 10h12.48a1.76 1.76 0 010 3.52H9.093a1.76 1.76 0 010-3.52zm3.785 4.24h8.694a1.76 1.76 0 010 3.52H12.88a1.76 1.76 0 110-3.52zm4.214 4.24h4.48a1.76 1.76 0 010 3.52h-4.48a1.76 1.76 0 010-3.52z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M9.094 10h12.48a1.76 1.76 0 010 3.52H9.093a1.76 1.76 0 010-3.52zm3.785 4.24h8.694a1.76 1.76 0 010 3.52H12.88a1.76 1.76 0 110-3.52zm4.214 4.24h4.48a1.76 1.76 0 010 3.52h-4.48a1.76 1.76 0 010-3.52z"
        fill="#DF374D"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={7.333}
          y1={10}
          x2={18.854}
          y2={25.36}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3AA5FF" />
          <stop offset={1} stopColor="#8258C3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
