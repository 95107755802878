import React from 'react'

import { useReloadOnRotate } from 'src/shared/hooks/useReloadOnRotate'

import { breakpoints } from './breakpoints'

const getState = () => ({
  isMobile:
    typeof window !== 'undefined'
      ? window.innerWidth <= breakpoints.mobile
      : false,

  isTabletXS:
    typeof window !== 'undefined'
      ? window.innerWidth <= breakpoints.tabletXS
      : false,

  isTablet:
    typeof window !== 'undefined'
      ? window.innerWidth > breakpoints.mobile &&
        window.innerWidth <= breakpoints.tablet
      : false,

  isDesktopXS:
    typeof window !== 'undefined'
      ? window.innerWidth > breakpoints.desktopXS
      : false,

  isDesktop:
    typeof window !== 'undefined'
      ? window.innerWidth > breakpoints.tablet
      : false,
  innerWidth: typeof window !== 'undefined' ? window.innerWidth : 1024,
})

export const DimensionsContext = React.createContext(getState())

export const DimensionsProvider: React.FC<{}> = ({ children }) => {
  const [state, setState] = React.useState(getState())

  useReloadOnRotate()

  React.useEffect(() => {
    const updateState = () => setState(getState())
    window.addEventListener('resize', updateState)
    return () => window.removeEventListener('resize', updateState)
  }, [])

  return (
    <DimensionsContext.Provider value={state}>
      {children}
    </DimensionsContext.Provider>
  )
}
