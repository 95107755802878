import React from 'react'

import { styled } from 'src/ui/theme'

type Props = {
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  color?: string
  className?: string
}

const lineHeights: {
  [key: string]: number
} = {
  h1: 88,
  h2: 58,
  h3: 52,
  h4: 29,
  h5: 22,
}

const fontSize: {
  [key: string]: number
} = {
  h1: 72,
  h2: 48,
  h3: 40,
  h4: 24,
  h5: 18,
}

const StyledHeader = styled.div<{
  size: string
  color?: string
}>`
  font-size: ${({ size }) => fontSize[size]}px;
  line-height: ${({ size }) => lineHeights[size]}px;
  font-family: ${({ theme }) => theme.fonts.headers};
  color: ${({ theme, color }) => (color ? color : theme.colors.black.primary)};
  letter-spacing: 0.02em;
  word-break: break-word;
`

export const Header: React.FC<Props> = ({
  size,
  as,
  color,
  children,
  className,
}) => (
  <StyledHeader size={size} as={as} color={color} className={className}>
    {children}
  </StyledHeader>
)
