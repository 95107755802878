import React from 'react'

type Props = {
  color?: string
}

export const Watch: React.FC<Props> = ({ color }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0007 5.33325C26.7265 5.33325 21.5708 6.89723 17.1855 9.82739C12.8001 12.7576 9.38223 16.9223 7.36388 21.795C5.34553 26.6677 4.81745 32.0295 5.84639 37.2023C6.87532 42.3751 9.41508 47.1266 13.1445 50.8562C16.8739 54.5855 21.6254 57.1252 26.7983 58.1543C31.9711 59.1831 37.3329 58.6551 42.2055 56.6367C47.0783 54.6183 51.243 51.2005 54.1732 46.8151C57.1033 42.4298 58.6673 37.274 58.6673 31.9999C58.6673 28.4981 57.9774 25.0304 56.6374 21.795C55.2974 18.5597 53.3332 15.62 50.8569 13.1437C48.3806 10.6675 45.4409 8.70325 42.2055 7.36312C38.9703 6.02301 35.5025 5.33325 32.0007 5.33325ZM32.0007 53.3332C27.7815 53.3332 23.6568 52.0821 20.1485 49.738C16.6403 47.3938 13.9059 44.062 12.2912 40.1639C10.6766 36.2658 10.2541 31.9762 11.0772 27.838C11.9004 23.6997 13.9322 19.8985 16.9157 16.915C19.8992 13.9315 23.7005 11.8997 27.8388 11.0765C31.9769 10.2534 36.2665 10.6758 40.1646 12.2905C44.0628 13.9052 47.3945 16.6395 49.7388 20.1478C52.0828 23.656 53.334 27.7805 53.334 31.9999C53.3276 37.6559 51.078 43.0783 47.0785 47.0778C43.0791 51.0772 37.6567 53.3269 32.0007 53.3332ZM33.778 16.7773H28.4447V35.4439H44.446V30.1106H33.778V16.7773Z"
      fill={color ?? '#4F4FF4'}
    />
  </svg>
)
