import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
    color?: string
}

export const Check: React.FC<Props> = ({ color }) => {
    const theme = useTheme()
    return (
        <svg width={16} height={12} viewBox="0 0 16 12" fill="none">
            <path
                d="M14.71 1.21a.999.999 0 00-1.42 0L5.84 8.67 2.71 5.53A1.022 1.022 0 001.29 7l3.84 3.84a1 1 0 001.42 0l8.16-8.16a.999.999 0 000-1.47z"
                fill={color ? color : theme.colors.white.primary}
            />
        </svg>
    )
}
