import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialInst = ({ color }: Props) => {
  const theme = useTheme()
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 2.168h7a4.837 4.837 0 0 1 4.833 4.833v7a4.837 4.837 0 0 1-4.833 4.834h-7A4.837 4.837 0 0 1 1.667 14v-7A4.837 4.837 0 0 1 6.5 2.168Zm-.167 1.667c-1.658 0-3 1.341-3 3v7.333c0 1.658 1.342 3 3 3h7.333c1.659 0 3-1.342 3-3V6.835c0-1.659-1.341-3-3-3H6.333Zm8.042 1.25a1.042 1.042 0 1 1 0 2.084 1.042 1.042 0 0 1 0-2.084ZM10 6.335c2.3 0 4.166 1.866 4.166 4.166 0 2.3-1.866 4.167-4.166 4.167a4.168 4.168 0 0 1-4.167-4.167c0-2.3 1.867-4.166 4.167-4.166ZM10 8a2.497 2.497 0 0 0-2.5 2.5c0 1.384 1.117 2.5 2.5 2.5s2.5-1.116 2.5-2.5c0-1.383-1.117-2.5-2.5-2.5Z"
        fill={color ?? theme.colors.black.total}
      />
    </svg>
  )
}
