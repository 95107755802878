import React from 'react'
import ReactSelect, { components } from 'react-select'
import { animated, useTransition } from 'react-spring'
import { css } from 'styled-components'

import { styled, useTheme } from 'src/ui/theme'
import { Body } from 'src/ui/typography'
import { Icons } from 'src/ui/icons'
import { HBox } from 'src/ui/layout'

const Wrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const Caption = styled(Body)<{ pl?: number; color?: string }>`
  height: 21px;
  color: ${({ theme, color }) => color ?? theme.colors.black.primary};

  ${({ pl }) => pl && `padding-left: ${pl}px`}
`

const showScrollbar = css`
  div:last-child > div > div {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
`

const Container = styled.div<{
  active?: boolean
  isMobile?: boolean
  error: boolean
}>`
  border: 0px solid
    ${({ theme, active }) =>
      active ? theme.colors.brand.primary : theme.colors.gray.bg};
  background: ${({ theme }) => theme.colors.gray.bg};
  border-radius: 4px;
  box-shadow: ${({ error }) => (error ? '0px 2px 0px 0px #F40D0D' : 'none')};
  /* box-shadow: ${({ theme, active }) =>
    active ? theme.shadows.primary : 'none'}; */

  height: 56px;

  ${showScrollbar};

  ${({ isMobile }) =>
    isMobile
      ? `
    position: absolute;
    top:0;
    width: 100%;
    pointer-events:none;
  `
      : ''}
`

const MobileSelect = styled.select`
  height: 56px;
  width: 100%;
  outline: none;
  opacity: 0;
`

const SelectValue = styled.div`
  white-space: nowrap;
  cursor: default;
`

const ValueContainer = styled.div`
  padding: 16px;
  white-space: nowrap;
`

const OptionText = styled.div<{ active?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  padding: 16px 16px;
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.colors.brand.primary : theme.colors.black.primary};
`

const AnimatedArrow = styled.div<{ isOpen: boolean }>`
  height: 56px;
  display: flex;
  align-items: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'none')};
  transform-origin: 50% 50%;
  transition: 0.3s all;
  padding: 0 16px;
`

type Props = {
  value: string
  options: TOption[]
  description?: string
  placeholder?: string
  emptyText?: string
  isMobile?: boolean
  caption?: string
  error?: string | boolean
  name?: string
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (value: TOption) => void
}

export const Select: React.FC<Props> = (props) => {
  const theme = useTheme()
  const [value, setValue] = React.useState(
    props.options.find((el) => el.value === props.value)
  )

  const onChange = (option: TOption): void => {
    props.onChange(option)
    setValue(option)
  }

  const customSelectStyles = {
    control: () => ({
      display: 'flex',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: () => ({
      height: 56,
      alignSelf: 'start',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 0,
      boxShadow: theme.shadows.primary,
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '8px 0',
    }),
    singleValue: () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    placeholder: () => ({}),
    option: () => ({}),
    noOptionsMessage: () => ({}),
  }

  const nativeContainer = (props: any) => (
    <Container
      error={props.error}
      isMobile
      active={props.selectProps.menuIsOpen || props.isFocused}
    >
      {props.children}
    </Container>
  )

  return (
    <Wrapper isMobile={props.isMobile}>
      {props.caption && (
        <>
          <Caption size="secondary">{props.caption}</Caption>
          <HBox height={8} />
        </>
      )}
      <ReactSelect
        error={props.error}
        name={props.name}
        value={value}
        options={props.options}
        onChange={(val) => {
          onChange(val as TOption)
        }}
        isSearchable={false}
        menuIsOpen={props.isMobile ? false : undefined}
        placeholder={props.placeholder || ''}
        emptyText={props.emptyText || 'Пусто'}
        styles={customSelectStyles}
        components={{
          SelectContainer: props.isMobile
            ? nativeContainer
            : SelectContainerComponent,
          IndicatorSeparator: null,
          Placeholder: PlaceholderComponent,
          SingleValue: SingleValueComponent,
          DropdownIndicator: DropdownIndicatorComponent,
          Menu: MenuComponent,
          Option: OptionComponent,
          NoOptionsMessage: NoOptionsMessageComponent,
        }}
      />
      {props.isMobile ? (
        <MobileSelect
          onChange={(e) => {
            onChange(props.options.find((opt) => opt.value === e.target.value))
            setValue(props.options.find((opt) => opt.value === e.target.value))
          }}
        >
          {props.options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </MobileSelect>
      ) : null}
      <Caption color={theme.colors.red.error} size="secondary" pl={16}>
        {props.error}
      </Caption>
    </Wrapper>
  )
}

const SelectContainerComponent: React.FC = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <Container
        error={props.selectProps.error}
        active={props.selectProps.menuIsOpen || props.isFocused}
      >
        {props.children}
      </Container>
    </components.SelectContainer>
  )
}

const PlaceholderComponent: React.FC = (props: any) => {
  const theme = useTheme()
  return (
    <components.Placeholder {...props}>
      <ValueContainer>
        <Body size={'primary'} color={theme.colors.gray.primary}>
          {props.selectProps.placeholder}
        </Body>
      </ValueContainer>
    </components.Placeholder>
  )
}

const SingleValueComponent: React.FC = (props: any) => {
  const theme = useTheme()
  return (
    <components.SingleValue {...props}>
      <ValueContainer>
        <SelectValue>
          <Body color={theme.colors.black.primary}>
            {props.selectProps.value.label}
          </Body>
        </SelectValue>
      </ValueContainer>
    </components.SingleValue>
  )
}

const DropdownIndicatorComponent: React.FC = (props: any) => {
  const theme = useTheme()
  return (
    <components.DropdownIndicator {...props}>
      <AnimatedArrow isOpen={props.selectProps.menuIsOpen}>
        <Icons.ArrowDown
          color={
            props.isFocused
              ? theme.colors.brand.primary
              : theme.colors.black.primary
          }
        />
      </AnimatedArrow>
    </components.DropdownIndicator>
  )
}

const MenuComponent: React.FC = (props: any) => {
  const transitions = useTransition(props.selectProps.menuIsOpen, null, {
    from: { opacity: 0, position: 'relative', zIndex: 1 },
    enter: { opacity: 1, position: 'relative', zIndex: 1 },
    leave: { opacity: 0, position: 'relative', zIndex: 1 },
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props: fxProps }) =>
          item && (
            <animated.div key={key} style={fxProps}>
              <components.Menu {...props}>{props.children}</components.Menu>
            </animated.div>
          )
      )}
    </>
  )
}

const OptionComponent: React.FC = (props: any) => {
  return (
    <components.Option {...props}>
      <OptionText active={props.isFocused}>{props.data.label}</OptionText>
    </components.Option>
  )
}
const NoOptionsMessageComponent: React.FC = (props: any) => {
  const theme = useTheme()
  return (
    <components.NoOptionsMessage {...props}>
      <ValueContainer>
        <SelectValue>
          <Body color={theme.colors.gray.primary}>
            {props.selectProps.emptyText}
          </Body>
        </SelectValue>
      </ValueContainer>
    </components.NoOptionsMessage>
  )
}
