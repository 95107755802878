import { useEffect, useState } from 'react'

const detectLandscape = () =>
  window.matchMedia('(orientation: landscape)').matches

/**
 * Отслеживает изменение размеров экрана по ширине с учетом ориентации.
 * При изменении ориентации с портретной на альбомную происходит перезагрузка страницы.
 * Выбрано такое решение, т.к. для мобильных устройств вьюпорт установлен константой - 320px, и при
 * изменении ориентации не происходит обновление брейкпоинтов, которые зависят от свойства innerWidth,
 * которое зависит от вьюпорта.
 */
export const useReloadOnRotate = () => {
  if (typeof window === 'undefined') {
    return
  }
  const [currentWidth, setCurrentWidth] = useState(window.screen.width)

  const resizeHandler = () => {
    const isLandscape = detectLandscape()

    if (!isLandscape) {
      setCurrentWidth(window.screen.width)
      return
    }

    if (isLandscape && window.screen.width !== currentWidth) {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])
}
