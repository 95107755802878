import React from 'react'
import ReactDOM from 'react-dom'
import { styled, useTheme, TDefaultTheme } from 'src/ui/theme'
import { useTransition, animated } from 'react-spring'
import { Row } from 'src/ui/layout'
import { breakpoints } from 'src/ui/dimensions'
import { Icons } from 'src/ui/icons'
import { Touchable } from 'src/ui/atoms'
import { Header } from 'src/ui/typography'

const Wrapper = styled(animated.div)<{ isFullScreen?: boolean }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  top: 0;
  left: 0;
  padding: ${({ isFullScreen }) => (isFullScreen ? 0 : 16)}px;
  background: ${({ isFullScreen, theme }) =>
    isFullScreen ? theme.colors.white.primary : 'rgba(0, 0, 0, 0.4)'};
  z-index: 9999;
  overflow: auto;
  @media (max-width: ${breakpoints.mobile}px) {
    overflow: hidden;
  }
`
const Table = styled.table`
  height: 100%;
  width: 100%;
`

const TableRow = styled.tr`
  height: 100%;
  width: 100%;
`

const TableCol = styled.td`
  vertical-align: middle;
  width: 100%;
`
const Content = styled.div<{
  isFullScreen?: boolean
  backgroundColor: string
  color: string
}>`
  display: flex;
  flex-flow: column;
  overflow: auto;
  margin: 0 auto;
  padding: ${({ isFullScreen }) => (isFullScreen ? '32px 16px' : '72px  64px')};
  width: 100%;
  max-width: 1300px;
  height: ${({ isFullScreen }) => (isFullScreen ? '115vh' : 'auto')};
  max-width: ${({ isFullScreen }) => (isFullScreen ? '100%' : '1300px')};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 4px;
`

const ModalHeader = styled(Row)`
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
`

const Body = styled.div`
  flex: 1;
  overflow: auto;

  @media (max-width: ${breakpoints.mobile}px) {
    padding-bottom: 160px;
  }
`

type TColors = 'default' | 'white'
type TElements = {
  title: string
  button: {
    color: string
    hover: string
  }
  contentBackground: string
  contentColor: string
}

const getColors = (theme: TDefaultTheme): Record<TColors, TElements> => ({
  default: {
    button: {
      color: theme.colors.white.primary,
      hover: theme.colors.brand.secondary,
    },
    contentBackground: theme.colors.brand.primary,
    contentColor: theme.colors.white.primary,
    title: theme.colors.white.primary,
  },
  white: {
    button: {
      color: theme.colors.black.bg,
      hover: theme.colors.black.primary,
    },
    contentBackground: theme.colors.white.primary,
    contentColor: theme.colors.black.bg,
    title: theme.colors.black.bg,
  },
})

type Props = {
  title: string
  modalIsVisible: boolean
  color?: TColors
  isFullScreen?: boolean
  onClose: () => void
}

export const Modal: React.FC<Props> = ({
  title,
  modalIsVisible,
  onClose,
  children,
  isFullScreen,
  color = 'default',
}) => {
  if (typeof document === 'undefined') {
    return null
  }

  const theme = useTheme()
  const portal = document.getElementById('modal')

  const colorValues = getColors(theme)[color]

  React.useEffect(() => {
    if (modalIsVisible) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = `${window.innerHeight - 200}px`
    } else {
      document.body.style.overflow = 'visible'
      document.body.style.height = `auto`
    }
  }, [modalIsVisible])

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const animations = {
    fullScreen: {
      from: { transform: 'translate3d(0, 100vh, 0)' },
      enter: { transform: 'translate3d(0, 0px, 0)' },
      leave: { transform: 'translate3d(0, 100vh, 0)' },
    },
    default: {
      from: { opacity: '0' },
      enter: { opacity: '1' },
      leave: { opacity: '0' },
    },
  }

  const transitions = useTransition(modalIsVisible, null, {
    config: {
      duration: 300,
    },
    from: isFullScreen ? animations.fullScreen.from : animations.default.from,
    enter: isFullScreen
      ? animations.fullScreen.enter
      : animations.default.enter,
    leave: isFullScreen
      ? animations.fullScreen.leave
      : animations.default.leave,
  })

  return portal
    ? ReactDOM.createPortal(
        transitions.map(
          ({ item, key, props }) =>
            item && (
              <Wrapper
                key={key}
                style={props}
                onClick={onClose}
                isFullScreen={isFullScreen}
              >
                <Table>
                  <tbody>
                    <TableRow>
                      <TableCol>
                        <Content
                          backgroundColor={colorValues.contentBackground}
                          color={colorValues.contentColor}
                          isFullScreen={isFullScreen}
                          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation()
                          }}
                        >
                          <ModalHeader>
                            <Header
                              size={isFullScreen ? 'h4' : 'h2'}
                              color={colorValues.title}
                            >
                              {title}
                            </Header>
                            <Touchable
                              onPress={onClose}
                              hoverColor={colorValues.button.hover}
                            >
                              <Icons.Close color={colorValues.button.color} />
                            </Touchable>
                          </ModalHeader>
                          <Body>{children}</Body>
                        </Content>
                      </TableCol>
                    </TableRow>
                  </tbody>
                </Table>
              </Wrapper>
            )
        ),
        portal
      )
    : null
}
