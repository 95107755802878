import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { Col, VBox, Wrapper } from 'src/ui/layout'
import { MenuItem } from 'src/ui/molecules'

type Props = {
  items: TMenuItem[]
  withoutShadow?: boolean
}

const Container = styled(Col)<{ $withoutShadow?: boolean }>`
  position: fixed;
  justify-content: space-between;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.white.primary};
  height: calc(100% - 56px);
  width: 100%;
  overflow: auto;
  z-index: 9999;

  @media (min-width: ${breakpoints.tablet}px) {
    position: sticky;
    top: -1px;
    height: auto;
    overflow: hidden;
    padding: 0;
    justify-content: center;
    box-shadow: none;
    box-shadow: ${({ $withoutShadow }) =>
      !$withoutShadow ? '0px 13px 16px rgba(0, 0, 0, 0.03)' : 'none'};
  }
`

const Items = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

export const Nav: React.FC<Props> = ({ items, withoutShadow }) => {
  return (
    <Container $withoutShadow={withoutShadow}>
      <Wrapper>
        <Items>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <MenuItem active={item.active} link={item.value}>
                {item.label}
              </MenuItem>
              <VBox />
            </React.Fragment>
          ))}
        </Items>
      </Wrapper>
      {typeof window !== 'undefined' &&
      window.innerWidth < breakpoints.tablet ? (
        <Wrapper>
          <MenuItem isBack link="https://kode.ru" external>
            К основному сайту
          </MenuItem>
        </Wrapper>
      ) : null}
    </Container>
  )
}
