import React from 'react'

import { styled } from 'src/ui/theme'
import { Icons } from 'src/ui/icons'
import { Touchable } from 'src/ui/atoms'
import { Row, VBox } from 'src/ui/layout'

const Wrapper = styled(Row)<{ error?: boolean }>``

const Container = styled.div<{ error?: boolean; color?: string }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  border: 2px solid
    ${({ theme, error, color }) =>
      error ? theme.colors.red.error : color ?? theme.colors.white.primary};
  border-radius: 4px;
`
type Props = {
  value?: boolean
  error?: boolean
  label?: JSX.Element
  color?: string
  onPress: () => void
}
export const Checkbox: React.FC<Props> = ({
  value,
  label,
  error,
  color,
  onPress,
}) => (
  <Touchable onPress={onPress}>
    <Row>
      <Container color={color} error={error}>
        {value ? <Icons.Check color={color} /> : null}
      </Container>
      <VBox />
      <Wrapper error={error}>{label}</Wrapper>
    </Row>
  </Touchable>
)
