import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const ArrowRight: React.FC<Props> = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path d="M9 6l6 6-6 6" stroke={color ? color : theme.colors.black.primary} strokeWidth={2} />
    </svg>
  )
}
