import React, { useState } from 'react'
import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { Icons } from 'src/ui/icons'
import { styled } from 'src/ui/theme'
//@ts-expect-error
import bannerImage from './sims.jpg'
//@ts-expect-error
import analyticsImage from './analytics.jpg'
import { useEffect } from 'react'

const DEFAULT_BANNER_DELAY = 4000 // 5s

const STORAGE_KEY = 'banner-shown'

const bannersTypes = {
  sims: {
    url:
      'https://career.kode.ru/it-image?utm_source=webr&utm_medium=organic&utm_campaign=simskode&utm_content=hrweb',
    img: bannerImage,
    title: "Собираем айтишника&nbsp;на работу мечты",
    buttonText: "Играть",
  },
  analytics: {
    url: 'https://simulator.kode.ru',
    title: "Симулятор системного анализа",
    buttonText: "Пройти",
    img: analyticsImage,
  },
}

type TProps = {
  type: keyof typeof bannersTypes
  delay?: number
}

export const Banner = ({ type, delay = DEFAULT_BANNER_DELAY }: TProps) => {
  const { isMobile } = useDimensions()

  const [isBannerShown, setIsBannerShown] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const bannerShown = localStorage.getItem(STORAGE_KEY)

      setTimeout(() => {
        setIsBannerShown(bannerShown === 'true')
      }, delay)
    }
  }, [])

  const handleBannerClose = () => {
    localStorage.setItem(STORAGE_KEY, 'true')
    setIsBannerShown((prev) => !prev)
  }

  if (isBannerShown) {
    return null
  }

  const banner = bannersTypes[type]
  return (
    <FixedArea isMobile={isMobile}>
      <Wrapper isMobile={isMobile}>
        <CloseButtonWrapper onClick={handleBannerClose}>
          <Icons.Close />
        </CloseButtonWrapper>

        <DescriptionCol>
          <Title>{banner.title}</Title>

          <ActionBtn
            target="_blank"
            href={banner.url}
            rel="noopener noreferrer"
          >
            {banner.buttonText}
          </ActionBtn>
        </DescriptionCol>

        <Link target="_blank" href={banner.url} rel="noopener noreferrer">
          <BannerImage src={banner.img} alt="alt" />
        </Link>
      </Wrapper>
    </FixedArea>
  )
}

const FixedArea = styled.div<{
  isMobile: boolean
}>`
  position: fixed;
  inset: 24px;
  top: unset;
  z-index: 10000;

  @media (min-width: ${breakpoints.tablet}px) {
    inset: 40px;
    top: unset;
    left: unset;
  }
`

const Wrapper = styled.div<{
  isMobile: boolean
}>`
  position: relative;
  display: flex;

  flex-direction: row;
  border: ${({ theme }) => theme.colors.white.primary} 1px solid;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px #15151526;
  background-color: ${({ theme }) => theme.colors.white.primary};
  width: 100%;
  max-width: 390px;
  max-height: 126px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column-reverse;
    max-width: 264px;
    max-height: 100%;
  }
`

const DescriptionCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 10px 12px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 24px 16px 20px;
  }
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.brand.primary};
  text-decoration: none;
`

const ActionBtn = styled.a`
  align-self: flex-start;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white.primary};
  background-color: ${({ theme }) => theme.colors.brand.primary};
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 32px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.secondary};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  user-drag: none;
  user-select: none;
  display: block;
  max-height: 126px;

  @media (min-width: ${breakpoints.tablet}px) {
    max-height: 100%;
  }
`

const Title = styled.p`
  flex: 1;
  display: block;
  width: 100%;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.headers};
  color: ${({ theme, color }) => (color ? color : theme.colors.black.primary)};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 22px;
  word-break: break-word;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
    text-align: center;
  }
`

const CloseButtonWrapper = styled.button`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white.primary};
  border-radius: 50%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }

  &:after {
    position: absolute;
    content: '';
    inset: -12px;
  }

  & > svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    top: 8px;
    right: 8px;
    transform: scale(0.8);
  }
`
