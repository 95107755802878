import React from 'react'

type Props = {
  color?: string
}

export const Rub = ({ color }: Props) => {
  return (
    <svg
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.68 20V8.80005H10.872C12.2907 8.80005 13.4053 9.12005 14.216 9.76005C15.0373 10.4 15.448 11.3174 15.448 12.512C15.448 13.7174 15.0373 14.64 14.216 15.28C13.4053 15.92 12.2907 16.24 10.872 16.24H7.336L7.864 15.68V20H6.68ZM7.864 15.776L7.336 15.232H10.888C11.976 15.232 12.808 14.9974 13.384 14.528C13.9707 14.0587 14.264 13.392 14.264 12.528C14.264 11.664 13.9707 10.9974 13.384 10.528C12.808 10.0587 11.976 9.82405 10.888 9.82405H7.336L7.864 9.26405V15.776ZM5 17.92V17.216H11.464V17.92H5Z"
        fill={color ?? '#04040C'}
      />
    </svg>
  )
}
