import React from 'react'
import { styled, useTheme } from 'src/ui/theme'

import { VBox } from 'src/ui/layout'
import { Touchable } from 'src/ui/atoms'
import { Icons } from 'src/ui/icons'

const FileText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme }) => theme.colors.white.primary};
  font-size: 16px;
  line-height: 24px;
`

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.brand.secondary};
  border-radius: 85px;
  max-width: 225px;
  overflow: hidden;
`
type Props = {
  text: string
  onRemove: () => void
}

export const File: React.FC<Props> = ({ text, onRemove }) => {
  const theme = useTheme()
  return (
    <Container>
      <FileText>{text}</FileText>
      <VBox />
      <Touchable onPress={onRemove}>
        <Icons.Close color={theme.colors.white.primary} />
      </Touchable>
    </Container>
  )
}
