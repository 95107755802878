import React, { forwardRef, useImperativeHandle } from 'react'
import { Swiper } from 'swiper'
import { useSwiper } from 'swiper/react/swiper-react.js'

/**
 * Компонент для установления методов свайпера в ref
 * Предзназначен для обхода прямой передачи ref в Swiper, так как он не принимает данный проп
 **/
export const SwiperRefComponent = forwardRef<Swiper>((_, ref) => {
    const swiper = useSwiper()

    useImperativeHandle(ref, () => swiper)

    return null
})
