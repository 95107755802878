import * as React from 'react'

export const ProjectManagement = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.332 9.333h14M32.668 9.333h14M18.665 18.667H14a4.667 4.667 0 00-4.667 4.667V42a4.667 4.667 0 004.667 4.667h4.666A4.667 4.667 0 0023.332 42V23.334a4.667 4.667 0 00-4.667-4.667zM42.001 18.667h-4.666a4.667 4.667 0 00-4.667 4.667V28a4.667 4.667 0 004.667 4.667H42A4.667 4.667 0 0046.668 28v-4.666a4.667 4.667 0 00-4.667-4.667z"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
