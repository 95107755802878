import { ThemedStyledProps } from 'styled-components'

export const defaultTheme = {
  colors: {
    white: {
      primary: '#FFFFFF',
      secondary: '#FCFCFC',
    },
    brand: {
      primary: '#4F4FF4',
      secondary: '#7272F8',
    },
    black: {
      bg: '#04040C',
      primary: '#373737',
      secondary: '#6B6B6B',
      total: '#000000',
    },
    gray: {
      bg: '#F1F1F8',
      primary: '#858585',
      divider: '#F9F9FB',
      headerBg: '#FAFAFF',
      jobsBg: '#FBFBFB',
      gray80: '#797979',
    },
    red: {
      error: '#F40D0D',
    },
  },
  fonts: {
    default: 'Montserrat',
    headers: 'Gilroy-ExtraBold',
  },
  paddings: {
    quarter: 4,
    half: 8,
    main: 16,
    mainAndHalf: 24,
    double: 32,
  },
  shadows: {
    primary: `0px 4px 8px rgba(21, 21, 21, 0.15)`,
  },
}

export type TDefaultTheme = typeof defaultTheme

export type ThemedProps<P> = ThemedStyledProps<P, TDefaultTheme>
