import React from 'react'
import { styled } from 'src/ui/theme'

import { HBox } from 'src/ui/layout'
import { Icons } from 'src/ui/icons'
import { Input, File } from 'src/ui/molecules'
import { Body } from 'src/ui/typography'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Caption = styled(Body)<{ $color?: string }>`
  height: 21px;
  color: ${({ theme, $color }) => $color ?? theme.colors.black.primary};
`

const FileInput = styled.input`
  visibility: hidden;
  position: absolute;
`

type Props = {
  textName: string
  fileName: string
  file: File | null
  textValue?: string
  accept?: string[]
  disabled?: boolean
  error?: string | boolean
  placeholder?: string
  caption?: string
  captionColor?: string
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveFile: () => void
}

const UploadControl = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: border-color 0.3s;
  &:hover {
    border-color: ${({ theme }) => theme.colors.gray.primary};
  }
`

export const InputWithUpload: React.FC<Props> = ({
  textName,
  fileName,
  textValue,
  file,
  accept,
  disabled,
  error,
  placeholder,
  caption,
  captionColor,
  onFileChange,
  onTextChange,
  onRemoveFile,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onFileChange(e)
    }
  }

  const onRemoveFileHandler = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = ''

      onRemoveFile()
    }
  }

  return (
    <Wrapper>
      {caption && (
        <>
          <Caption size="secondary" $color={captionColor}>
            {caption}
          </Caption>
          <HBox height={8} />
        </>
      )}
      <Input
        variant="light"
        name={textName}
        value={file ? '' : textValue}
        onChange={onTextChange}
        disabled={disabled}
        placeholder={file ? '' : placeholder}
        error={error}
        leftAdornment={
          file ? (
            <File text={file.name} onRemove={onRemoveFileHandler} />
          ) : undefined
        }
        rightAdornment={
          <>
            <HBox height={8} />
            <UploadControl>
              <Icons.Upload />
              <FileInput
                ref={fileInputRef}
                onChange={onFileChangeHandler}
                type="file"
                accept={accept?.join(', ')}
                disabled={disabled}
                name={fileName}
              />
            </UploadControl>
          </>
        }
      />
    </Wrapper>
  )
}
