import * as React from 'react'

export const Analytics = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.667 37.333H9.333A2.333 2.333 0 007 39.666v7A2.333 2.333 0 009.333 49h2.334A2.333 2.333 0 0014 46.666v-7a2.333 2.333 0 00-2.333-2.333z"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 46.667A2.333 2.333 0 0016.333 49h8.764a2.334 2.334 0 002.236-1.664l2.8-7c.21-.707.31-1.47-.131-2.062-.439-.593-1.265-.94-2.002-.94h-4.667v-5.757a2.566 2.566 0 00-4.701-1.423L14 37.334v9.333zM32.668 7v9.333a2.333 2.333 0 002.333 2.334h9.334"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.668 28.233V11.667A4.667 4.667 0 0116.335 7h16.333l11.667 11.667v25.666A4.667 4.667 0 0139.668 49h-5.367"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
