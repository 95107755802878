import { styled } from 'src/ui/theme'

type Props = {
  height?: number
}

export const HBox = styled.div<Props>`
  flex: 0 0 ${({ theme, height }) => height || theme.paddings.main}px;
  height: ${({ theme, height }) => height || theme.paddings.main}px;
`
