import React from 'react'
import { createStore, createEvent } from 'effector'
import { useStore } from 'effector-react'

import { Snack } from 'src/ui/organisms'

export const addMessage = createEvent<{ text: string; id: string }>(
  'addMessage'
)
export const removeMessage = createEvent<string>('removeMessage')

const addMessageHandler = (
  state: { text: string; id: string }[],
  message: { text: string; id: string }
) => {
  if (state.find((mes) => mes.id === message.id) === undefined) {
    setTimeout(() => {
      removeMessage(message.id)
    }, 3000)

    return [...state, message]
  } else {
    return state
  }
}
const removeMessageHandler = (
  state: { text: string; id: string }[],
  id: string
) => {
  return state.filter((m) => m.id !== id)
}

const messages = createStore<{ text: string; id: string }[]>([])
  .on(addMessage, addMessageHandler)
  .on(removeMessage, removeMessageHandler)

export const SnackContainer: React.FC = () => {
  const mess = useStore(messages)

  return <Snack messages={mess} onClose={(id) => removeMessage(id)} />
}
