import React from "react"

export const RuwardIcon = () => {
    return <svg width="200" height="80" viewBox="0 0 716 434" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8_4)">
    <path d="M78.3438 432.271C75.1068 432.271 72.0701 430.704 70.1942 428.066L48.7883 397.964C46.0437 398.269 43.1975 398.422 40.2497 398.422H11.5844V432.271H0.30127V325.539H40.2497C53.8708 325.539 64.544 328.791 72.2694 335.297C79.9948 341.803 83.8575 350.748 83.8575 362.133C83.8575 370.468 81.7229 377.533 77.4536 383.327C73.2859 389.019 67.2886 393.136 59.4615 395.677L85.5347 432.271H78.3438ZM39.9447 388.816C50.5163 388.816 58.5975 386.478 64.1883 381.802C69.779 377.126 72.5744 370.569 72.5744 362.133C72.5744 353.492 69.779 346.885 64.1883 342.311C58.5975 337.635 50.5163 335.297 39.9447 335.297H11.5844V388.816H39.9447Z" fill="#7A74E0"/>
    <path d="M153.486 433.186C139.662 433.186 128.887 429.221 121.161 421.293C113.436 413.364 109.573 401.827 109.573 386.681V325.539H120.856V386.224C120.856 398.625 123.652 407.875 129.243 413.974C134.833 420.073 142.915 423.123 153.486 423.123C164.159 423.123 172.291 420.073 177.882 413.974C183.473 407.875 186.268 398.625 186.268 386.224V335.539C186.268 330.016 190.745 325.539 196.268 325.539H197.246V386.681C197.246 401.827 193.384 413.364 185.658 421.293C178.035 429.221 167.311 433.186 153.486 433.186Z" fill="#7A74E0"/>
    <path d="M377.775 325.539L341.791 432.271H329.898L298.641 341.243L267.231 432.271H255.491L219.507 325.539H231.095L261.895 417.328L293.762 325.539H304.283L335.692 417.786L364.649 332.329C366.024 328.27 369.834 325.539 374.12 325.539H377.775Z" fill="#7A74E0"/>
    <path d="M462.124 403.758H402.659L389.851 432.271H378.11L426.902 325.539H438.033L486.825 432.271H474.932L462.124 403.758ZM458.007 394.61L432.391 337.279L406.775 394.61H458.007Z" fill="#7A74E0"/>
    <path d="M582.523 432.271C579.286 432.271 576.25 430.704 574.374 428.066L552.968 397.964C550.223 398.269 547.377 398.422 544.429 398.422H515.764V432.271H504.481V325.539H544.429C558.05 325.539 568.724 328.791 576.449 335.297C584.174 341.803 588.037 350.748 588.037 362.133C588.037 370.468 585.903 377.533 581.633 383.327C577.466 389.019 571.468 393.136 563.641 395.677L589.714 432.271H582.523ZM544.124 388.816C554.696 388.816 562.777 386.478 568.368 381.802C573.959 377.126 576.754 370.569 576.754 362.133C576.754 353.492 573.959 346.885 568.368 342.311C562.777 337.635 554.696 335.297 544.124 335.297H515.764V388.816H544.124Z" fill="#7A74E0"/>
    <path d="M614.668 325.539H658.123C669.406 325.539 679.368 327.826 688.008 332.4C696.648 336.873 703.357 343.175 708.135 351.307C712.912 359.337 715.301 368.536 715.301 378.905C715.301 389.273 712.912 398.523 708.135 406.655C703.357 414.686 696.648 420.988 688.008 425.562C679.368 430.035 669.406 432.271 658.123 432.271H614.668V325.539ZM657.513 422.513C666.865 422.513 675.048 420.683 682.062 417.024C689.076 413.262 694.514 408.129 698.377 401.624C702.239 395.016 704.171 387.443 704.171 378.905C704.171 370.366 702.239 362.844 698.377 356.339C694.514 349.731 689.076 344.598 682.062 340.939C675.048 337.177 666.865 335.297 657.513 335.297H625.951V422.513H657.513Z" fill="#7A74E0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M354.301 0.814209C292.74 1.72581 236.994 26.6466 196.012 66.63L280.171 150.789C299.596 132.325 325.598 120.717 354.301 119.842V0.814209Z" fill="#7A74E0"/>
    <path d="M275.248 155.766C256.543 175.884 245.103 202.85 245.103 232.487H126.103C126.103 169.989 150.848 113.269 191.075 71.593L275.248 155.766Z" fill="#7A74E0"/>
    <path d="M524.527 71.593L440.354 155.766C459.06 175.884 470.5 202.85 470.5 232.487H589.5C589.5 169.989 564.755 113.269 524.527 71.593Z" fill="#7A74E0"/>
    <path d="M415.375 42.0608L358.25 263.686L352.589 259.7C348.666 256.938 346.986 251.953 348.436 247.38L413.75 41.4358L415.375 42.0608Z" fill="#7A74E0"/>
    <path d="M393.825 125.668C409.528 130.962 423.683 139.622 435.432 150.789L519.591 66.63C478.608 26.6466 422.863 1.72581 361.301 0.814209V119.842C363.488 119.908 365.659 120.037 367.813 120.227C372.324 120.624 376.709 118.28 378.543 114.139L410.75 41.4358L415.375 42.0608L393.825 125.668Z" fill="#7A74E0"/>
    </g>
    <defs>
    <clipPath id="clip0_8_4">
    <rect width="716" height="434" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    
}