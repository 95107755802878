import React from 'react'

type Props = {
  size?: number
  color?: string
}

export const Recruiting = ({ size = 56, color = '#848484' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3978_4481)">
        <path
          d="M20.9993 25.6667C26.154 25.6667 30.3327 21.488 30.3327 16.3333C30.3327 11.1787 26.154 7 20.9993 7C15.8447 7 11.666 11.1787 11.666 16.3333C11.666 21.488 15.8447 25.6667 20.9993 25.6667Z"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 49V44.3333C7 41.858 7.98333 39.484 9.73367 37.7337C11.484 35.9833 13.858 35 16.3333 35H25.6667C28.142 35 30.516 35.9833 32.2663 37.7337C34.0167 39.484 35 41.858 35 44.3333V49"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.334 7.30322C39.3416 7.81726 41.1211 8.98486 42.3918 10.6219C43.6625 12.259 44.3523 14.2725 44.3523 16.3449C44.3523 18.4173 43.6625 20.4307 42.3918 22.0678C41.1211 23.7049 39.3416 24.8725 37.334 25.3866"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49 49.0001V44.3334C48.9882 42.2735 48.2951 40.2753 47.029 38.6504C45.7628 37.0254 43.9946 35.8651 42 35.3501"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3978_4481">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
