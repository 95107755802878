import React from 'react'
import YouTube from 'react-youtube'

export type TReadyTarget = {
  stopVideo: () => void
  pauseVideo: () => void
  playVideo: () => void
}

type Props = {
  videoId: string
  height?: number
  onReady: (e: { target: TReadyTarget }) => void
  onPlay: () => void
  onPause: () => void
}
export const Video: React.FC<Props> = ({
  videoId,
  height = 320,
  onReady,
  onPause,
  onPlay,
}) => {
  const opts = {
    height: height.toString(),
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      showinfo: 0,
      fs: 0,
    },
  }
  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onReady={onReady}
      onPlay={onPlay}
      onPause={onPause}
    />
  )
}
