import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialDrib = ({ color }: Props) => {
  const theme = useTheme()
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.683 15.851c-.35-1.6-.767-3.075-1.183-4.375a9.25 9.25 0 0 1 1.316-.091h.017c.775 0 1.625.1 2.55.308a6.49 6.49 0 0 1-2.7 4.158ZM10 17.001a6.432 6.432 0 0 1-3.867-1.283 9.47 9.47 0 0 1 1.517-1.85 8.88 8.88 0 0 1 3.375-2.05 32.76 32.76 0 0 1 1.283 4.758 6.35 6.35 0 0 1-2.308.425Zm-6.5-6.5v-.091c.183.008.425.008.708.008h.008c1.3-.008 3.584-.117 5.9-.842.126.275.25.559.376.859a10.432 10.432 0 0 0-3.667 2.15c-.859.8-1.45 1.575-1.792 2.083A6.42 6.42 0 0 1 3.5 10.501Zm3.625-5.833c.458.542 1.358 1.717 2.325 3.542-1.95.591-3.942.725-5.133.725h-.109c-.2 0-.375 0-.517-.009.476-1.866 1.726-3.425 3.434-4.258ZM10 4.001c1.533 0 2.941.534 4.05 1.425-.85 1.025-1.967 1.742-3.192 2.284A24.718 24.718 0 0 0 8.616 4.15c.45-.091.909-.15 1.384-.15Zm5.108 2.484a6.52 6.52 0 0 1 1.383 3.708c-.941-.2-1.825-.292-2.658-.292h-.008c-.667 0-1.292.059-1.883.159-.142-.35-.276-.684-.434-1.009 1.317-.575 2.575-1.4 3.6-2.566ZM10 2.168a8.336 8.336 0 0 0-8.333 8.333c0 4.6 3.733 8.334 8.333 8.334s8.333-3.734 8.333-8.334S14.6 2.168 10 2.168Z"
        fill={color ?? theme.colors.black.total}
      />
    </svg>
  )
}
