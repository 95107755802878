import React from 'react'

type Props = {
  color?: string
}

export const PopularSearch: React.FC<Props> = ({ color }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.2194 55.448L43.5052 39.7333C46.8423 35.437 48.4162 30.0306 47.9068 24.6146C47.3975 19.1986 44.8431 14.1803 40.7636 10.5815C36.6842 6.98277 31.3866 5.07407 25.9493 5.2441C20.512 5.4141 15.344 7.65005 11.4974 11.4966C7.65078 15.3432 5.41483 20.5113 5.24483 25.9486C5.07481 31.3858 6.9835 36.6834 10.5823 40.7629C14.181 44.8424 19.1993 47.3968 24.6153 47.9061C30.0314 48.4154 35.4378 46.8416 39.734 43.5045L55.4487 59.2186L59.2194 55.448ZM26.6675 42.6666C23.503 42.6666 20.4096 41.7282 17.7784 39.9704C15.1472 38.2122 13.0964 35.7133 11.8854 32.7896C10.6744 29.8661 10.3576 26.649 10.9749 23.5453C11.5923 20.4416 13.1161 17.5907 15.3538 15.353C17.5914 13.1154 20.4423 11.5915 23.546 10.9742C26.6497 10.3568 29.8668 10.6737 32.7903 11.8847C35.714 13.0957 38.213 15.1464 39.9711 17.7776C41.729 20.4088 42.6674 23.5022 42.6674 26.6667C42.6626 30.9088 40.9754 34.9757 37.9759 37.9752C34.9764 40.9746 30.9095 42.6618 26.6675 42.6666Z"
      fill={color ?? '#4F4FF4'}
    />
  </svg>
)
