import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Adornments } from 'src/ui/atoms'
import { Label, Header } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'

import Img, { FluidObject } from 'gatsby-image'

import VisibilitySensor from 'react-visibility-sensor'
import { useSpring, animated } from 'react-spring'

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white.primary};
  padding: 0 16px;

  height: 808px;
  overflow: hidden;
  transform: translateZ(0);

  @media (min-width: 2000px) {
    overflow: visible;
  }
`

const StyledWrapper = styled(Wrapper)`
  @media (max-width: ${breakpoints.tablet}px) {
    padding-left: 16px;
  }
`

const Content = styled(animated.div)`
  position: absolute;
  bottom: 40px;

  padding: 56px 64px;
  max-width: 674px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 3;

  transform: translateZ(0);
  backdrop-filter: blur(80px);
`

const Image = styled(Img)`
  position: absolute !important;
  object-fit: top center;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 1376px;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
  transform: translateX(32px);

  @media (min-width: 2000px) {
    width: 99.9%;
  }
`

const Adornment1 = styled.div`
  position: absolute;

  right: -196px;
  bottom: 36px;
  z-index: 1;
  opacity: 0.5;
  transform: translateZ(0);
`
const Adornment2 = styled.div`
  position: absolute;

  left: 298px;
  top: 30px;
  z-index: 1;
  transform: translateZ(0);
`

type Props = {
  background?: FluidObject
  title: string
  titleColor?: string
  titleSize?: 'h1' | 'h2'
  titleAs?: 'h1' | 'h2'
  label?: string
  adornment?: boolean
}

export const HeroDesktop: React.FC<Props> = ({
  title,
  background,
  titleColor,
  titleSize = 'h1',
  titleAs,
  label,

  adornment,
  children,
}) => {
  const theme = useTheme()
  const [visible, setVisible] = React.useState(false)
  const [delay, setDelay] = React.useState(0)

  React.useEffect(() => {
    const splash = document.getElementsByClassName('splash')
    if (splash.length > 0) {
      setDelay(1500)
    }
  }, [])

  const contentAnimation = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateX(-60px)',
  })

  const adornment1Animation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(120px) scale(5)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0) scale(1)',
    },
    delay: 200 + delay,
  })

  const adornment2Animation = useSpring({
    from: {
      opacity: 0,
      transform: 'translateX(120px) scale(5)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0) scale(1)',
    },
    delay: 500 + delay,
  })

  const handler = (active: boolean) => {
    if (active) {
      setVisible(true)
    }
  }

  return (
    <VisibilitySensor onChange={handler} partialVisibility>
      <Container>
        {background ? (
          <Wrapper>
            <Image fluid={background} />
          </Wrapper>
        ) : null}

        {adornment ? (
          <>
            <Adornment1>
              <animated.div style={adornment1Animation}>
                <Adornments.DashedSquareXL color={theme.colors.white.primary} />
              </animated.div>
            </Adornment1>
            <Adornment2>
              <animated.div style={adornment2Animation}>
                <Adornments.DashedSquareXL color={theme.colors.white.primary} />
              </animated.div>
            </Adornment2>
          </>
        ) : null}

        <StyledWrapper>
          <Content style={contentAnimation}>
            <HBox />
            {label ? (
              <>
                <Label color={theme.colors.white.primary} weight="bold">
                  {label}
                </Label>
                <HBox height={8} />
              </>
            ) : null}
            <Header
              size={titleSize}
              as={titleAs}
              color={titleColor ? titleColor : theme.colors.white.primary}
            >
              {title}
            </Header>

            {children}
          </Content>
        </StyledWrapper>
      </Container>
    </VisibilitySensor>
  )
}
