import React from 'react'

import { styled } from 'src/ui/theme'

type Props = {
  color?: string
}

const StyledCaption = styled.div<{
  color?: string
}>`
  font-size: 11px;
  line-height: 13px;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme, color }) => (color ? color : theme.colors.black.primary)};
`

export const Caption: React.FC<Props> = ({ children, color }) => <StyledCaption color={color}>{children}</StyledCaption>
