import React from 'react'

type Props = {
  size?: number
  color?: string
}

export const VoiceUi = ({ size = 56, color = '#848484' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3978_4327)">
        <path
          d="M42 18.6665C43.8565 18.6665 45.637 19.404 46.9497 20.7168C48.2625 22.0295 49 23.81 49 25.6665C49 27.523 48.2625 29.3035 46.9497 30.6163C45.637 31.929 43.8565 32.6665 42 32.6665"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.334 18.6665V44.3332C23.334 44.952 23.0882 45.5455 22.6506 45.9831C22.213 46.4207 21.6195 46.6665 21.0007 46.6665H18.6673C18.0485 46.6665 17.455 46.4207 17.0174 45.9831C16.5798 45.5455 16.334 44.952 16.334 44.3332V32.6665"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28 18.6669L38.556 9.8702C38.8627 9.61472 39.2359 9.45196 39.6318 9.40098C40.0278 9.35001 40.43 9.41293 40.7914 9.58239C41.1529 9.75184 41.4585 10.0208 41.6726 10.3578C41.8866 10.6947 42.0002 11.0857 42 11.4849V39.8489C42.0002 40.2481 41.8866 40.639 41.6726 40.976C41.4585 41.3129 41.1529 41.5819 40.7914 41.7514C40.43 41.9208 40.0278 41.9837 39.6318 41.9328C39.2359 41.8818 38.8627 41.719 38.556 41.4635L28 32.6669H9.33333C8.71449 32.6669 8.121 32.421 7.68342 31.9835C7.24583 31.5459 7 30.9524 7 30.3335V21.0002C7 20.3814 7.24583 19.7879 7.68342 19.3503C8.121 18.9127 8.71449 18.6669 9.33333 18.6669H28"
          stroke={color}
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3978_4327">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
