import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialIn = ({ color }: Props) => {
  const theme = useTheme()
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 18h-3.333v-5.625c0-.883-.992-1.617-1.875-1.617-.884 0-1.459.734-1.459 1.617V18H7.5V8h3.333v1.667C11.383 8.775 12.8 8.2 13.75 8.2c2.083 0 3.75 1.7 3.75 3.758V18ZM5.833 18H2.5V8h3.333v10ZM4.167 3c.916 0 1.666.75 1.666 1.667 0 .916-.75 1.666-1.666 1.666-.917 0-1.667-.75-1.667-1.666C2.5 3.75 3.25 3 4.167 3Z"
        fill={color || theme.colors.black.total}
      />
    </svg>
  )
}
