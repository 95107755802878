import React from 'react'
import { useTransition, animated } from 'react-spring'
import { styled, useTheme } from 'src/ui/theme'
import { VBox, Row } from 'src/ui/layout'
import { Icons } from 'src/ui/icons'
import { Touchable } from 'src/ui/atoms'
import { Body } from 'src/ui/typography'

type Props = {
  messages: { text: string; id: string }[]
  onClose: (id: string) => void
}

const Item = styled(Row)`
  padding: 18px;
  align-items: center;
  background: ${({ theme }) => theme.colors.brand.primary};
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 16px;
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 16px;
  z-index: 9999;
`

export const Snack: React.FC<Props> = ({ messages, onClose }) => {
  const theme = useTheme()

  const transitions = useTransition(messages, (item) => item.id, {
    config: {
      duration: 300,
    },
    from: { opacity: '0', transform: 'translate3d(0, 100vh, 0)', maxHeight: 0 },
    enter: {
      opacity: '1',
      transform: 'translate3d(0, 0px, 0)',
      maxHeight: 200,
    },
    leave: {
      opacity: '0',
      transform: 'translate3d(0, 100vh, 0)',
      maxHeight: 0,
    },
  })
  return (
    <Wrapper>
      {transitions.map(({ item, key, props }) => {
        return (
          <animated.div key={key} style={props}>
            <Item>
              <Body weight="medium" color={theme.colors.white.primary}>
                {item.text}
              </Body>
              <VBox />
              <Touchable onPress={() => onClose(item.id)}>
                <Icons.Close color={theme.colors.white.primary} />
              </Touchable>
            </Item>
          </animated.div>
        )
      })}
    </Wrapper>
  )
}
