import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

const weights: {
  [key: string]: number
} = {
  regular: 300,
  bold: 600,
}

export const Label = styled.div<{
  color?: string
  weight?: 'regular' | 'bold'
}>`
  font-size: 14px;
  line-height: 17px;

  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme, color }) => (color ? color : theme.colors.black.primary)};
  font-weight: ${({ weight }) =>
    weight ? weights[weight] : weights['regular']};

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 10px;
    line-height: 12px;
  }
`
