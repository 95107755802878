import React from 'react'
import CSS from 'csstype'

import { styled } from 'src/ui/theme'

type Props = {
  size?: 'primary' | 'secondary'
  weight?: 'regular' | 'medium' | 'bold'
  as?: 'p' | 'span' | 'li'
  color?: string
  style?: CSS.Properties
  linkUnderline?: boolean
}

const sizes: {
  [key: string]: number
} = {
  primary: 16,
  secondary: 14,
}

const weights: {
  [key: string]: number
} = {
  regular: 300,
  medium: 500,
  bold: 600,
}

type BodyProps = {
  as: 'p' | 'span' | 'li'
  color?: string
  size: 'primary' | 'secondary'
  weight: 'regular' | 'medium' | 'bold'
  linkUnderline?: boolean
}
const StyledBody = styled.div<BodyProps>`
  font-size: ${({ size }) => sizes[size]}px;
  font-weight: ${({ weight }) => weights[weight]};
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.default};
  color: ${({ theme, color }) => (color ? color : theme.colors.black.primary)};

  a {
    text-decoration: ${({ linkUnderline }) => (linkUnderline ? 'underline' : 'none')};
    color: ${({ theme, color }) => color ?? theme.colors.brand.primary};
    transition: color 0.3s;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.brand.secondary};
    }
  }
`

export const Body: React.FC<Props> = ({
  size = 'primary',
  weight = 'regular',
  as = 'p',
  color,
  children,
  linkUnderline,
  ...props
}) => (
  <StyledBody
    size={size}
    weight={weight}
    as={as}
    color={color}
    linkUnderline={linkUnderline}
    {...props}
  >
    {children}
  </StyledBody>
)
