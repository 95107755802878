import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
  className?: string
}

export const SocialTikTok = ({ color, className }: Props) => {
  const theme = useTheme()
  return (
    <svg
      width={20}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.692 10.5c-.73 0-1.444.205-2.051.59a3.547 3.547 0 0 0-1.36 1.57 3.331 3.331 0 0 0-.21 2.023 3.448 3.448 0 0 0 1.01 1.792c.517.49 1.175.823 1.891.958a3.88 3.88 0 0 0 2.133-.2 3.657 3.657 0 0 0 1.657-1.289A3.371 3.371 0 0 0 11.385 14V3.5c0 1.16.486 2.273 1.351 3.094A4.747 4.747 0 0 0 16 7.875"
        stroke={color || theme.colors.black.total}
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
