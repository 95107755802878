import React from 'react'
import { useTheme } from 'src/ui/theme'

export const Logo: React.FC<{ color?: string }> = ({ color }) => {
  const theme = useTheme()

  return (
    <svg width={110} height={24} viewBox="0 0 110 24" fill="none">
      <path
        fill={color ? color : theme.colors.black.primary}
        d="M87.273 20.469h22.599v2.973H87.273zM87.273 10.506h22.599v2.973H87.273zM87.273.546h22.599v2.973H87.273z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.993 11.96c0 6.607 5.322 11.962 11.886 11.962 6.561-.007 11.878-5.358 11.885-11.961C50.764 5.355 45.443 0 38.879 0S26.993 5.355 26.993 11.96zm2.943.013c0-4.97 4.004-9 8.943-9V2.96c2.373 0 4.65.95 6.328 2.64a9.029 9.029 0 012.615 6.372c0 4.97-4.004 9-8.943 9-4.94 0-8.943-4.03-8.943-9zM56.899.426h22.82V23.39h-22.82V.426zm19.865 19.992V3.398h-16.91v17.02h16.91z"
        fill={color ? color : theme.colors.black.primary}
      />
      <path
        fill={color ? color : theme.colors.black.primary}
        d="M.083.489h2.954v22.944H.083zM19.755 23.33L8.508 12.013 19.857.59l2.086 2.1-9.259 9.32 9.158 9.22-2.087 2.1z"
      />
    </svg>
  )
}
