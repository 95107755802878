module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KODE","short_name":"KODE","start_url":"/","background_color":"#4F4FF4","theme_color":"#4F4FF4","display":"standalone","icon":"static/images/splash-logo.svg","include_favicon":false,"crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P29C8JV","includeInDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
