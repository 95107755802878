import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { Touchable } from 'src/ui/atoms'
import { Row, VBox } from 'src/ui/layout'
import { Icons } from 'src/ui/icons'
import { Body } from 'src/ui/typography'
import { breakpoints } from 'src/ui/dimensions'

type Props = {
  children: string
  active?: boolean
  isBack?: boolean
  link: string
  external?: boolean
}

const Container = styled(Row)<{ active?: boolean }>`
  padding: 16px 0;
  @media (min-width: ${breakpoints.mobile}px) {
    padding: 10px 0;
    border-bottom: 1px solid
      ${({ active, theme }) =>
        active ? theme.colors.brand.primary : 'transparent'};
  }
`

export const MenuItem: React.FC<Props> = ({
  active,
  isBack,
  link,
  children,
  external,
}) => {
  const theme = useTheme()

  return (
    <Touchable link={link} external={external}>
      <Container active={active}>
        {isBack ? <Icons.ArrowUp /> : null}
        {active &&
        typeof window !== 'undefined' &&
        window.innerWidth < breakpoints.tablet ? (
          <Icons.ArrowRight
            color={
              active ? theme.colors.brand.primary : theme.colors.black.primary
            }
          />
        ) : null}
        {typeof window !== 'undefined' &&
        window.innerWidth < breakpoints.tablet ? (
          <>
            <VBox width={8} />
            <Body
              weight={active ? 'medium' : 'regular'}
              color={
                active ? theme.colors.brand.primary : theme.colors.black.primary
              }
            >
              {children}
            </Body>
          </>
        ) : (
          <Body
            size={'secondary'}
            color={
              active ? theme.colors.black.primary : theme.colors.gray.primary
            }
          >
            {children}
          </Body>
        )}
      </Container>
    </Touchable>
  )
}
