import React from 'react'

import { styled } from 'src/ui/theme'
import { Label as LabelText } from 'src/ui/typography'

const Wrapper = styled(LabelText)`
  display: inline-block;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.black.secondary};
  background: ${({ theme }) => theme.colors.gray.bg};
  white-space: pre;
  border-radius: 4px;
  margin-bottom: 16px;
`

type Props = {
  children: string
}
export const Label: React.FC<Props> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
)
