import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialTg: React.FC<Props> = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M20.949 6.275l-2.717 11.88c-.204.838-.739 1.047-1.498.652l-4.14-2.828-1.996 1.78c-.221.206-.406.377-.832.377l.297-3.909L17.734 7.8c.334-.276-.072-.429-.518-.153l-9.483 5.538-4.083-1.185c-.888-.258-.904-.824.185-1.219l15.969-5.705c.739-.258 1.386.152 1.145 1.2z"
        fill={color ? color : theme.colors.brand.primary}
      />
    </svg>
  )
}
