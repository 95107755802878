import React from 'react'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useSpring, animated } from 'react-spring'
import { usePrevious } from 'src/shared/hooks/usePrevious'
import { styled, useTheme } from 'src/ui/theme'
import { Row, HBox } from 'src/ui/layout'
import { Touchable } from 'src/ui/atoms'
import { Body } from 'src/ui/typography'

type Props = {
  header: string
  children: JSX.Element
  defaultOpen?: boolean
  excerpt?: string
}

const Container = styled.div`
  will-change: transform, opacity, height;
`

const Header = styled(Row)`
  justify-content: space-between;
  padding: 8px 0;
`

const Title = styled(Body)`
  text-transform: uppercase;
`

const Excerpt = styled(Body)`
  padding: 8px 16px;
`

const Control = styled(Body)`
  white-space: pre;
`

export const Accordion: React.FC<Props> = React.memo(
  ({ children, defaultOpen = false, header, excerpt }) => {
    const theme = useTheme()
    const [isOpen, setOpen] = React.useState(defaultOpen)
    const previous = usePrevious(isOpen)
    const [ref, { height: viewHeight }] = useMeasure({
      polyfill: ResizeObserver,
    })

    //@ts-ignore
    const { height, opacity } = useSpring({
      config: {
        duration: 200,
      },
      from: { height: viewHeight, opacity: 0 },
      to: {
        height: isOpen ? viewHeight : 0,
        opacity: isOpen ? 1 : 0,
      },
    })

    return (
      <Container>
        <Header>
          <Title size="primary" weight="bold">
            {header}
          </Title>
          <Touchable
            onPress={() => setOpen(!isOpen)}
            hoverColor={theme.colors.brand.secondary}
          >
            <Control weight="medium" color={theme.colors.brand.primary}>
              {isOpen ? 'Свернуть' : 'Показать все'}
            </Control>
          </Touchable>
        </Header>
        {isOpen ? <HBox height={40} /> : <Excerpt>{excerpt}</Excerpt>}
        <div
          style={{
            opacity,
            height: isOpen && previous === isOpen ? 'auto' : height,
            overflow: 'hidden',
          }}
        >
          <animated.div
            ref={ref}
            style={{ opacity, height: isOpen ? 'auto' : height }}
            children={children}
          />
        </div>
      </Container>
    )
  }
)
