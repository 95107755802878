import * as React from 'react'

export const Ios = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.001 16.334c-7 0-9.333 7-9.333 12.833 0 7 4.667 17.5 9.333 17.5 2.539-.107 3.918-1.167 7-1.167 3.062 0 3.5 1.167 7 1.167s9.334-7 9.334-11.667c-.066-.023-5.768-.94-5.834-7-.044-5.063 5.638-6.892 5.834-7-2.387-3.481-6.886-4.58-8.167-4.666-3.344-.26-6.603 2.333-8.167 2.333-1.586 0-4.433-2.333-7-2.333zM28 9.334a4.666 4.666 0 004.667-4.667A4.667 4.667 0 0028 9.334"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
