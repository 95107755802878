import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialFb: React.FC<Props> = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <path
        d="M18.286 4H5.714A1.714 1.714 0 004 5.714v12.572A1.714 1.714 0 005.714 20h4.902v-5.44h-2.25V12h2.25v-1.951c0-2.22 1.322-3.446 3.345-3.446.97 0 1.983.173 1.983.173v2.178h-1.117c-1.1 0-1.443.683-1.443 1.383V12h2.456l-.393 2.56h-2.063V20h4.902A1.714 1.714 0 0020 18.286V5.714A1.714 1.714 0 0018.286 4z"
        fill={color ? color : theme.colors.brand.primary}
      />
    </svg>
  )
}
