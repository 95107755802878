import * as React from 'react'

export const QualityAssurance = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 21v-2.333a7 7 0 0114 0V21M18.665 21h18.667a14 14 0 012.333 7v7a11.667 11.667 0 01-23.333 0v-7a14 14 0 012.333-7M7 30.333h9.333M39.668 30.333h9.333M28 46.667v-14M9.332 44.334l7.817-4.667M46.666 44.334l-7.816-4.667M9.332 16.333l8.75 5.6M46.668 16.333l-8.75 5.6"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
