import * as React from 'react'

export const Peoples: React.FC = () => {
  return (
    <svg width={458} height={281} viewBox="0 0 458 281" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.754 43.593c3.031-3.562 5.083-7.927 4.74-12.818-.988-14.094-20.585-10.993-24.398-3.88-3.812 7.113-3.359 25.156 1.568 26.427 1.965.507 6.151-.735 10.411-3.305l-2.674 18.937h15.788l-5.435-25.361z"
        fill="#57331F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.286 32.33c-.604 8.428-2.203 12.642-4.796 12.642-3.889 0-7.19-2.472-8.896-2.472-1.245 0-.967 1.559-.142 2.714.68.954 3.994 1.873 3.994 3.35 0 1.479-2.618.335-2.618 1.212 0 1.45-.46 4.176 2.054 4.176 2.544 0 9.839-.928 11.089-4.708.807-2.44 1.329-7.495 1.565-15.166a3.949 3.949 0 014.418 3.923 3.94 3.94 0 01-1.189 2.827 59.754 59.754 0 003.11 4.03c4.406-2.646 7.676-10.393 3.368-17.835-1.191-3.646-3.886-5.593-7.07-6.498-2.579-1.08-5.256-1.201-7.516-.554-4.43.38-8.386 1.733-9.507 2.536 0 4.198.813 5.444 7.754 5.334 1.706.902 3.017 2.454 4.382 4.49z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.459 123.45l-32.289 81.809-21.656 69.163h-8.552L206.07 123.45h29.389z"
        fill="#3D2216"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.96 123.45c.344 28.748 5.358 67.453 6.159 69.245.534 1.195 18.971 16.387 55.312 45.578l-5.89 6.309c-42.879-25.027-65.31-39.209-67.294-42.547-2.976-5.007-12.666-51.082-20.446-78.585h32.159z"
        fill="#57331F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M304.222 236.167c1.978.7 3.341 1.363 4.088 1.991.909.764 2.108 2.029 3.598 3.797l-23.849 28.452c-3.062-1.312-4.023-2.846-2.882-4.603a208.98 208.98 0 002.8-4.413l5.194-18.172c.1-.35.465-.552.814-.452l.018.006 2.773.88c1.923-.897 3.271-1.807 4.044-2.729.628-.749 1.193-2.037 1.695-3.865a1.315 1.315 0 011.707-.892zM185.014 271.229c.736 1.967 1.104 3.438 1.104 4.415 0 1.187-.197 2.92-.593 5.199h-37.104c-.965-3.191-.408-4.914 1.669-5.169 2.078-.254 3.804-.484 5.178-.689l17.246-7.696a.657.657 0 01.868.333l.008.017 1.108 2.692c1.923.899 3.486 1.348 4.688 1.348.977 0 2.326-.395 4.048-1.185a1.315 1.315 0 011.78.735z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.749 267.298l-40.659-4.417 30.133-139.431h36.417l-25.891 143.848z"
        fill="#69A1AC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.021 228.093l-26.67 31.601c-28.091-30.712-44.468-50.752-49.131-60.119-4.663-9.367-8.855-34.742-12.576-76.125h40.999c2.032 36.407 3.402 55.457 4.11 57.152.707 1.694 15.13 17.524 43.268 47.491z"
        fill="#89C5CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.914 115.284l-18.636 18.199c-5.422 2.315-9.865 4.872-13.329 7.67-.778.94-1.626 2.742 2.038 1.936 3.665-.806 7.555-1.49 8.244-.297.689 1.193-1.341 3.021-.068 4.583.849 1.04 3.702-2.27 8.562-9.933l19.378-11.437-6.189-10.721zm91.129-16.47l-14.406.054c10.972 35.583 16.796 54.215 17.473 55.893 1.522 3.776-1.7 9.528-3.077 12.619 4.487 2.009 4.009-5.433 9.668-2.798 5.166 2.406 9.096 6.766 15.065 3.079.733-.453 1.537-2.16-.405-3.493-4.841-3.323-11.816-9.138-12.759-11.052-1.285-2.61-5.138-20.711-11.559-54.303z"
        fill="#57331F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.146 57.906l-5.688-.977c-8.961 31.71-55.004 53.325-57.831 65.179-1.036 4.348 2.089 6.545.475 7.869l7.394 5.419c1.252-1.463 3.924 1.212 9.504-.659s46.146-39.284 46.146-76.831z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.578 62.121c.656 41.983-23.588 52.691-18.188 69.533 4.46 13.907 47.143 28.858 54.529 15.225 2.989-5.518 4.346-12.192 4.5-19.448 1.888 5.575 3.381 10.374 4.479 14.397 1.894 6.941 9.239 8.044 9.239 10.718l9.677-2.674c-.656-3.632 3.906-5.401 2.683-13.517-5.481-36.363-20.206-62.722-44.176-79.076l-.248-3.55a2.632 2.632 0 00-2.808-2.444l-17.719 1.24a2.634 2.634 0 00-2.442 2.812l.474 6.784z"
        fill="#2F3676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.644 41.273c2.779-3.766 4.524-8.263 3.842-13.118-1.963-13.986-21.31-9.518-24.621-2.157-3.312 7.362-1.609 25.326 3.397 26.248 1.997.367 6.088-1.165 10.162-4.028L89.07 67.293l15.759-1.109-7.184-24.911z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.021 22.993c.473-.54.701-1.28.893-1.98.2-.728.393-1.463.507-2.214.228-1.5.113-3.199-1.02-4.272-.909-.86-2.205-1.183-3.381-1.19a6.042 6.042 0 00-2.306.458c-.725.296-1.33.826-2.028 1.178-.138-1.59-.526-3.236-1.428-4.538-.867-1.253-2.157-1.9-3.58-1.995-1.462-.097-2.868.342-4.115 1.134-.313.199-.621.405-.915.635-.245.193-.498.421-.795.514-.332.102-.512-.08-.748-.311a5.506 5.506 0 00-.906-.707c-1.423-.9-3.227-1.185-4.753-.478-.704.326-1.374.842-1.82 1.52-.396.604-.645 1.535-1.22 1.977-.244.187-.44-.025-.685-.174-.367-.225-.72-.477-1.091-.695a4.76 4.76 0 00-1.664-.616c-.858-.136-2.022-.008-2.186 1.115-.062.422.039.862.097 1.279.08.572.162 1.143.252 1.714.068.433.145.85.257 1.273.06.225.227.641.145.876-.102.295-.678.172-.918.199-.475.053-.947.17-1.373.404-.33.182-.673.44-.79.84-.072.247-.031.492.028.737.07.293.024.454.008.756-.662-.27-2.926-.814-3.125.352-.066.388.175.805.359 1.115.322.545.729 1.035 1.142 1.5.862.971 1.868 1.79 2.936 2.476-1.153.613-1.355 2.196-.26 3.053.483.38 1.101.428 1.673.36.21-.023.507-.125.674-.1.09.013.191.082.315.081.814-.001 1.714-.26 2.493-.496a8.322 8.322 0 003.697-2.277c.262-.28.483-.447.86-.47.33-.02.66.048.99.033.874-.039 1.63-.52 2.42-.862.126 1.091.522 2.275 1.038 3.228.42.778 1.191.84 1.945.859 2.41.057 4.797-.364 7.195-.53-2.285.689-4.669 1.074-6.926 1.853-1.021.353-.178.998.3 1.453.788.749 1.402 1.702 1.852 2.72 1.033-1.56 2.972-2.677 4.782-2.347 2 .364 3.568 2.925 2.445 4.925-.653 1.16-1.94 1.681-3.003 2.235.917.829 1.215 2.128 1.807 3.198.291.528.69 1.12 1.271 1.27.215.056.462.044.64.204.22.197.285.431.56.595 1.096.654 2.726.483 3.587-.532.779-.917.466-2.26-.295-3.081 1.083-.544 3.072-1.284 2.508-2.99 2.404-.33 7.995-4.66 4.554-7.296 1.709-1.13 3.173-3.703 1.879-5.79-.995-1.606-3.093-2.208-4.778-2.178z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.757 123.576l-48.883 81.81-36.649 69.163h-7.894l61.405-150.973h32.021z"
        fill="#784931"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.052 271.356c.736 1.966 1.104 3.438 1.104 4.414 0 1.188-.197 2.921-.593 5.199H.46c-.964-3.191-.408-4.913 1.67-5.168 2.077-.254 3.803-.484 5.177-.689l17.246-7.696a.658.658 0 01.868.333l.008.017 1.108 2.692c1.923.898 3.486 1.347 4.689 1.347.977 0 2.326-.395 4.047-1.185a1.316 1.316 0 011.78.736z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.404 263.474l-15.223-4.61c6.67-19.96 14.844-41.924 24.524-65.894 9.679-23.969 20.074-47.101 31.186-69.394h42.995c-12.354 28.2-25.45 53.823-39.289 76.869-13.839 23.047-28.57 44.056-44.193 63.029z"
        fill="#5C63AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.971 123.576c4.613 28.749 13.512 65.478 14.352 66.612.56.755 20.714 7.516 60.464 20.282l-1.705 6.968c-47.402-6.847-72.378-12.158-74.93-15.935-3.827-5.666-22.938-50.424-30.34-77.927h32.159z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.813 204.446l-3.003 11.223c-35.453 3.113-57.177.864-65.169-6.747-7.993-7.612-17.103-36.06-27.329-85.346h40.999c6.479 40.359 10.101 61.385 10.866 63.079.766 1.694 15.311 7.625 43.636 17.791z"
        fill="#8991DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.835 207.567c2.063-.383 3.575-.49 4.536-.32 1.169.206 2.84.702 5.013 1.488l-6.439 36.576c-3.307.396-4.906-.452-4.796-2.544.11-2.092.184-3.833.22-5.223l-4.581-18.339a.658.658 0 01.479-.798l.018-.004 2.842-.625c1.217-1.74 1.93-3.202 2.139-4.388.17-.963.015-2.361-.464-4.195a1.318 1.318 0 011.033-1.628z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.043 117.098l-17.994 18.835c-5.339 2.504-9.69 5.214-13.055 8.13-.746.967-1.53 2.797 2.104 1.863 3.634-.934 7.499-1.753 8.229-.586.73 1.168-1.235 3.065.09 4.58.885 1.01 3.622-2.397 8.213-10.222l18.97-12.106-6.557-10.494zm92.412-22.842l-11.046 4.083c17.872 23.956 27.413 36.606 28.622 37.951 2.72 3.026 1.657 9.532 1.418 12.906 4.903.352 1.912-6.476 8.131-5.938 5.677.491 10.859 3.24 15.209-2.266.535-.677.707-2.555-1.574-3.143-5.683-1.463-14.225-4.537-15.764-6.012-2.099-2.011-10.431-14.538-24.996-37.58z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.513 119.588c-2.693.278-5.508 1.23-8.425 2.824a2.964 2.964 0 00.94 5.525c-.517.538-1.04 1.075-1.569 1.61l7.09 9.732c20.015-14.824 39.186-26.722 45.402-46.457 2.086-6.624-2.609-27.042-3.627-34.592l-7.43-1.62C80.75 78.3 71.05 99.78 54.512 119.588z"
        fill="#69A1AC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.933 123.633c-19.977 5.838-36.012 8.67-48.105 8.497-1.498-.021-2.535-6.285-2.163-8.111 5.786-28.44 9.244-49.122 5.993-67.591 2.097.664 5.485.221 10.162-1.33 16.255 16.754 26.031 37.545 34.113 68.535z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.271 126.116a2.963 2.963 0 00.412-2.789l-.675-1.856a2.962 2.962 0 00-2.947-1.946 49.912 49.912 0 01-1.071-2.799c-2.734-7.873-1.898-16.213-3.376-23.998 11.986 7.057 24.038 20.768 36.155 41.134l11.605-5.766a221.745 221.745 0 00-2.357-4.888c1.271-1.052 2.274-5.025.978-5.431a14.527 14.527 0 00-4.133-.659c-17.176-31.842-39.881-53.338-68.117-64.487l-2.254.398-5.115.903c7.224 28.332-11.71 96.318 8.12 148.02 19.992.901 45.098-17.323 73.708-11.359-4.79-21.386-29.172-43.197-40.933-64.477z"
        fill="#89C5CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.182 53.744l5.154 9.698-5.375 8.902 7.152 4.987-10.281 27.823 13.571-28.353-7.052-5.056 4.776-7.871-7.945-10.13z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.87 114.121a2.634 2.634 0 10-.915-5.19 2.634 2.634 0 00.915 5.19zm-.615 15.488a2.636 2.636 0 002.136-3.052 2.631 2.631 0 00-3.049-2.136 2.634 2.634 0 00.913 5.188zm1.978 15.03a2.635 2.635 0 00-.914-5.188 2.635 2.635 0 00.914 5.188z"
        fill="#000"
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.329 117.974c-1.232 16.768-80.604 66.136-81.067 67.521-.206.616 8.03 20.076 24.706 58.381l-6.939 2.689c-25.578-40.568-37.71-63.013-36.396-67.336 1.88-6.189 22.408-26.548 61.583-61.077l38.113-.178z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.141 235.225l-11.328 4.112c-23.886-26.875-33.249-49.049-31.542-60.347 1.138-7.532 21.595-27.857 61.371-60.975h40.499c-1.382 38.833-74.95 67.628-75.934 69.283-.984 1.654 8.7 17.862 16.934 47.927z"
        fill="#2F3676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.541 242.592c1.416 1.657 2.297 2.962 2.643 3.914.421 1.159.843 2.92 1.265 5.283l-36.218 13.2c-2.073-2.77-2.141-4.649-.203-5.636a209.796 209.796 0 004.809-2.514l14.105-13.644a.683.683 0 01.966.016l.013.014 2.037 2.232c2.196.192 3.88.074 5.054-.353.954-.348 2.13-1.213 3.531-2.596h-.001a1.366 1.366 0 011.999.084z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.203 117.973l35.358 84.645 34.751 71.56h-8.198l-89.703-156.205h27.792z"
        fill="#997659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M369.803 270.942c.764 2.043 1.146 3.571 1.146 4.585 0 1.234-.205 3.034-.616 5.4h-38.539c-1.001-3.314-.423-5.104 1.734-5.368 2.158-.264 3.951-.503 5.378-.716l17.913-7.994a.683.683 0 01.902.346l.008.018 1.151 2.796c1.997.933 3.621 1.4 4.87 1.4 1.014 0 2.415-.411 4.203-1.231a1.366 1.366 0 011.85.764z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.175 259.312l-18.218 3.407c-14.087-20.651-73.038-105.327-79.502-144.746h40.558c21.585 28.496 43.735 120.347 57.162 141.339z"
        fill="#5C63AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.562 35.6c2.174-4.32 3.16-9.197 1.6-13.994-4.495-13.823-23.495-5.783-25.576 2.28-2.08 8.065 2.856 25.966 8.142 25.992 2.108.011 6.022-2.285 9.682-5.926l2.003 19.583 15.927-3.983L286.562 35.6z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M261.863 27.517c-.07-.96.063-2.078.159-3.027.123-1.222.48-2.387.911-3.508a24.27 24.27 0 013.252-5.875c2.433-3.222 5.954-5.612 9.874-6.109 1.98-.25 4.025.007 5.912.817 1.705.732 3.483 1.993 5.369 1.642 2.053-.383 3.469-2.616 4.82-4.116 1.523-1.69 3.246-3.075 5.296-3.961 3.435-1.483 7.093-1.13 10.279 1.159 3.391 2.436 6.422 6.497 5.713 11.032-.132.843-.385 1.59-.845 2.27-.422.626-1.122 1.354-1.149 2.19-.028.888.935 1.783 1.391 2.464.653.975 1.249 2.021 1.425 3.216.287 1.961-.475 4.095-1.446 5.676-.989 1.608-2.413 2.746-4.045 3.516-1.09.514-2.23.933-3.415 1.14-.589.104-1.138.073-1.729.087-.575.014-.844.234-1.233.646-1.806 1.914-3.983 3.026-6.512 3.445-2.134.353-4.669.457-6.727-.535-2.285-1.1-2.785-3.284-2.914-5.72-.415.35-1.686.823-1.134-.298.219-.444.767-.74 1.11-1.055.401-.367.79-.809 1.048-1.308 1.04-2.016-1.255-5.01-3.214-5.106-2.91-.143-2.578 3.802-4.267 5.268-.971.843-2.324.364-3.351-.213-1.376-.775-2.481-1.98-3.489-3.237-.83-1.035-1.626-2.096-2.465-3.122-.392-.48-1.663-1.794-1.368-2.521-2.033.104-3.995 1.445-5.405 2.91-.583.607-.991 1.216-1.17 2.073-.039.183-.09.713-.301.787-.392.138-.362-.386-.38-.627z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.155 112.054l-21.515 16.321c-5.883 1.692-10.787 3.755-14.711 6.189-.922.867-2.024 2.608 1.856 2.243 3.879-.366 7.977-.576 8.536.734.559 1.311-1.765 2.93-.65 4.692.743 1.175 4.104-1.862 10.083-9.11l21.426-9.291-5.025-11.778zm83.684-.913l-14.276.305c9.309 28.192 14.33 43.149 15.063 44.871 1.649 3.875-1.593 9.877-2.968 13.095 4.695 1.996 4.065-5.687 9.99-3.066 5.409 2.393 9.568 6.826 15.7 2.908.754-.482 1.558-2.26-.483-3.603-5.087-3.345-12.435-9.227-13.449-11.188-1.381-2.674-4.574-17.114-9.577-43.322z"
        fill="#B28B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.225 52.174l-5.925-.906c-21.591 19.245-29.963 55.637-56.497 75.302l6.606 7.871c46.511-6.564 57.294-50.963 55.816-82.267z"
        fill="#4040D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.267 125.676l-52.273.914c-2.389.042-1.999-3.401-1.683-5.141 3.636-20.078 18.817-41.917 18.321-70.398l15.037-2.619c12.819 19.761 17.561 44.284 20.598 77.244z"
        fill="#DDE3E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M310.838 67.742c2.179-.665 3.888-2.158 5.126-4.478 5.339-10.002 3.253-15.42-2.667-17.11-5.921-1.69-11.109 2.095-20.493 2.259a9.491 9.491 0 00-1.401.12l-4.715.35c-12.747 45.956-15.951 76.524-9.612 91.705l41.144-.72c2.536 5.619 4.952 10.342 7.247 14.169l11.211-.196c.463-34-8.15-62.7-25.84-86.099z"
        fill="#4F4FF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.852 116.546c-1.106 10.806-3.073 18.67-5.9 23.59l15.269-.267c-2.959-6.555-6.082-14.329-9.369-23.323z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M345.665 40.597c-2.174 8.02-5.008 26.05-2.116 28.878h18.293l.006-.097c6.78-.751 12.053-6.506 12.053-13.494v-13.26c0-7.498-6.072-13.577-13.562-13.577h-1.261c-6.803 0-12.435 5.013-13.413 11.55z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M366.795 53.352c2.907-3.415 4.874-7.6 4.545-12.291-.947-13.514-19.739-10.54-23.394-3.72-3.655 6.82-3.221 24.12 1.503 25.34 1.884.486 5.898-.705 9.983-3.17l-2.564 18.159h15.139l-5.212-24.318z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.704 51.07c-.698 7.5-.419 16.065 1.66 18.099h18.293c1.02-17.728-8.831-10.863-8.831-27.554-.614-.6-1.194-1.195-1.768-1.708l.02-.49c-3.168-6.492-7.187-9.738-12.056-9.738-7.305 0-9.611 3.402-11.574 6.291 1.468 5.043 7.165 5.79 12.227 8.933a3.472 3.472 0 015.656 2.697 3.472 3.472 0 01-3.627 3.47z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M396.353 192.02c-15.133 4.237-28.189 6.355-39.168 6.355s-21.177-3.262-30.594-9.787c14.808-4.349 27.004-6.524 36.586-6.524 9.583 0 20.641 3.319 33.176 9.956z"
        fill="#1F28CF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.087 204.482c.146-.219.269-.458.367-.717 2.023-5.355 34.422-63.123 37.65-73.684h-36.477c-2.454 8.03-13.5 58.422-15.055 68.476-4.904 15.86-15.002 67.222-16.292 70.78-1.356 3.743 6.275 7.643 8.308 3.041 3.236-7.322 10.945-27.583 14.377-39.954 3.218-11.597 5.838-22.031 7.122-27.942z"
        fill="#BA8B72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.928 211.535c14.813 3.748 68.993 10.523 72.711 11.586 3.825 1.093 7.178-6.799 2.451-8.509-7.523-2.72-28.25-9.005-40.818-11.57-10.108-2.063-19.332-3.746-25.525-4.732.525-17.413 2.923-59.203 1.351-68.229h-32.275c1.507 8.655 14.25 71.808 16.618 78.13.752 2.256 2.994 3.254 5.487 3.324z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M448.744 212.511c1.978-.367 3.428-.47 4.349-.307 1.121.198 2.723.673 4.807 1.427l-6.174 35.072c-3.172.38-4.704-.434-4.599-2.44.106-2.006.176-3.675.211-5.008l-4.392-17.584a.631.631 0 01.459-.766l.017-.004 2.725-.599c1.167-1.668 1.851-3.071 2.051-4.207.163-.923.014-2.264-.445-4.023a1.263 1.263 0 01.903-1.541l.088-.02zM324.902 271.782c.706 1.886 1.059 3.297 1.059 4.233 0 1.139-.19 2.801-.569 4.985h-35.578c-.924-3.059-.391-4.711 1.601-4.955 1.992-.244 3.647-.465 4.964-.661l16.537-7.38a.63.63 0 01.833.32l.007.016 1.063 2.581c1.844.862 3.342 1.292 4.495 1.292.937 0 2.231-.378 3.881-1.136a1.261 1.261 0 011.707.705z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M383.994 130.081c-14.222-4.073-27.679-4.073-40.371 0-4.416 20.167-15.145 28.172-17.032 59.091 21.448 10.668 47.941-7.608 70.019 2.795 6.308-14.551-2.523-49.595-12.616-61.886z"
        fill="#2B44FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.808 105.019l-24.906-3.401c-4.982-2.774-9.565-4.652-13.75-5.634-1.178-.034-3.051.394-.26 2.723 2.791 2.329 5.622 4.905 5.125 6.148-.497 1.243-3.093.781-3.523 2.692-.286 1.275 3.926 1.482 12.637.622l20.208 8.02 4.469-11.17zm78.238 5.771l-13.812.052c15.146 34.12 23.043 51.985 23.692 53.594 1.46 3.621-1.629 9.136-2.95 12.1 4.302 1.927 3.844-5.21 9.271-2.682 4.953 2.307 8.721 6.487 14.444 2.952.704-.435 1.475-2.071-.388-3.35-4.641-3.186-11.33-8.762-12.233-10.597-1.233-2.503-7.24-19.859-18.024-52.069z"
        fill="#D4A181"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M363.212 66.935l-10.815.72c-2.664 24.123-17.172 39.36-49.655 34.202l-4.315 31.064c30.36 3.928 56.986-8.239 62.443-41.183 1.412-8.529 2.528-17.404 2.342-24.803z"
        fill="#AFB9C5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.546 63.788l-16.386 3.756c0 28.213-8.978 46.214-13.583 66.957h44.255c.623 7.168 1.006 14.535 1.205 22.101h33.137c-7.509-39.994-23.358-70.936-47.546-92.824h-1.074l-.008.01z"
        fill="#C5CFD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.851 94.251c1.14 17.581.47 30.997-2.012 40.25h8.994c-1.235-14.199-3.414-27.616-6.982-40.25z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.77 53.352c2.906-3.415 4.874-7.6 4.545-12.291-.947-13.514-19.739-10.54-23.394-3.72-3.656 6.82-3.221 24.12 1.503 25.34 1.884.486 5.897-.705 9.982-3.17l-2.564 18.159h15.139l-5.211-24.318z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.901 29.912c2.069-.631 5.072-.845 6.335-.7a10.918 10.918 0 01-.771-5.514c.226-1.817 1.037-3.363 1.787-4.984.346-.747.737-1.49 1.321-2.062a4.516 4.516 0 012.138-1.15 5.717 5.717 0 012.463-.002c.359.076.68.24 1.031.33.36.094.534-.131.795-.358.629-.545 1.552-.815 2.348-.911.893-.108 1.819.027 2.671.32.829.286 1.625.75 2.225 1.428.266.3.509.644.666 1.022.065.156.075.248.23.305.143.052.311.054.459.104 1.429.485 2.651 1.59 3.316 3.01.389.828.573 2.286.522 3.92-.05 1.634-1.524 5.898-2.903 7.453-1.23 1.388-3.039 2.305-4.785 2.706 1.159 1.325 1.93 2.982 2.5 4.667a22.86 22.86 0 011.145 5.67c.134 1.84.147 3.787-.284 5.589-.404 1.685-1.266 3.067-2.706 3.944-.659.401-1.369.703-2.083.979-.703.272-1.409.536-2.146.688-1.394.289-3.001.24-4.075-.872-.387-.4-.813-.904-1.095-1.393-.102-.176-.163-.291-.04-.463.148-.206.387-.364.577-.525a5.443 5.443 0 001.922-3.603c.172-1.48-.225-3.389-1.662-4.034-1.773-.797-3.726.118-5.302.97-.67-2.366-2.045-4.497-4.202-5.583-2.385-1.2-5.123-1.341-7.574-2.371-.639-.269-1.29-.574-1.76-1.12-.197-.23-.427-.526-.506-.829-.103-.397.153-.73.351-1.04 1.048-1.642 5.024-4.96 7.092-5.59z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.769 130.081l-46.873 78.444-35.141 66.318h-7.57l58.88-144.762h30.704z"
        fill="#784931"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.382 271.782c.706 1.885 1.058 3.297 1.058 4.233 0 1.138-.19 2.8-.568 4.985H62.294c-.925-3.06-.391-4.712 1.6-4.956 1.993-.244 3.647-.464 4.965-.661l16.536-7.379a.63.63 0 01.833.319l.008.017 1.062 2.581c1.844.861 3.343 1.292 4.496 1.292.937 0 2.23-.379 3.88-1.136a1.26 1.26 0 011.708.705z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.514 264.224l-14.596-4.42c6.395-19.139 14.233-40.2 23.514-63.183 9.282-22.984 19.25-45.164 29.904-66.54h41.226c-11.845 27.04-24.402 51.609-37.672 73.707-13.27 22.099-27.395 42.244-42.376 60.436z"
        fill="#DB2721"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.849 130.081c4.424 27.566 12.956 62.784 13.761 63.871.537.725 19.863 7.207 57.978 19.448l-1.635 6.682c-45.452-6.565-69.401-11.658-71.848-15.28-3.67-5.433-21.994-48.349-29.092-74.721h30.836z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.19 207.624l-2.879 10.761c-33.996 2.985-54.825.829-62.489-6.469-7.665-7.298-16.399-34.577-26.205-81.835h39.312c6.213 38.698 9.686 58.86 10.42 60.484.734 1.625 14.681 7.311 41.841 17.059z"
        fill="#FF4133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.718 210.617c1.978-.368 3.428-.471 4.35-.308 1.12.198 2.723.674 4.807 1.427l-6.174 35.072c-3.172.38-4.705-.433-4.599-2.439.105-2.006.176-3.676.211-5.009l-4.393-17.584a.631.631 0 01.459-.765l.018-.004 2.724-.6c1.168-1.668 1.852-3.07 2.052-4.207.162-.923.014-2.264-.445-4.023a1.262 1.262 0 01.99-1.56z"
        fill="#E4E4E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.825 90.741L120.93 78.737c-3.641-4.328-7.228-7.69-10.762-10.084-1.08-.452-2.968-.725-1.202 2.413 1.766 3.137 3.482 6.517 2.583 7.484-.9.966-3.143-.384-4.217 1.222-.715 1.07 3.117 2.762 11.498 5.075l15.91 14.582 8.085-8.688zm-34.291 10.129l-9.618-5.29c-1.35-5.14-2.962-7.726-4.84-7.758-1.441-.672 1.07 5.824-1.663 5.26-2.733-.563-9.404-5.53-10.554-4.521-1.7 1.492-.184 6.073 1.603 8.351 3.338 4.259 6.103 5.716 12.936 7.623 3.573.997 7.435 2.996 11.588 5.996l.548-9.661z"
        fill="#915B3C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.489 88.067c-7.669-.081-17.896-1.702-28.807-8.636l-4.286 9.566c7.527 7.478 18.11 13.622 27.364 13.361 7.293-.205 11.02-9.035 5.729-14.29z"
        fill="#2026A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.162 103.409c-8.141 2.032-21.794-.365-40.959-7.191-4.753 9.844-6.95 16.863-6.592 21.055 16.44 8.597 30.531 12.43 42.14 12.683-.369 6.895.469 13.456 3.286 19.451 5.79 12.325 39.695.911 51.095 3.158 7.139-23.209-6.664-32.641-6.675-47.492-.013-16.263-13.057-41.295-14.977-41.295h-17.502c-.966 12.42-5.827 26.108-9.816 39.631z"
        fill="#1F28CF"
      />
    </svg>
  )
}
