import React from 'react'

type Props = {
  color?: string
}

export const FullRightArrow: React.FC<Props> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.707 3.414L19.485 10.192H1V12.192H19.485L12.707 18.97L14.121 20.384L23.313 11.192L14.121 2L12.707 3.414Z"
      fill={color ?? '#858585'}
    />
  </svg>
)
