import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { TDefaultTheme } from './default-theme'
import { useTheme } from './use-theme'

const Styles = createGlobalStyle<{ theme: TDefaultTheme }>`

/* Reset styles */
 html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
      display: block;
    }
    body {
      line-height: 1;
      box-sizing: border-box;
    }
    body *{
      box-sizing: border-box;
    }
    ol, ul {
      list-style: none;
    }
    li{
      list-style: none;
    }
    blockquote, q {
      quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
      content: '';
      content: none;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }

    button{
      display: inline-block;
      border: none;
      padding: 0;
      margin: 0;
      text-decoration: none;
      background: none;
      color: inherit;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    
    body {
      font-family: ${({ theme }) => theme.fonts.default};
      line-height: 1.5;
      overflow-x: hidden!important;

      *::selection{
          background: ${({ theme }) => theme.colors.brand.primary};
          color:  ${({ theme }) => theme.colors.white.primary};
      }
      
    }

    @font-face {
      font-family: "Gilroy-ExtraBold";
      src: url("/fonts/gilroy-extra-bold.otf") format("opentype");
    }

    @font-face {
      font-family: "Gilroy-Light";
      src: url("/fonts/gilroy-light.otf") format("opentype");
    }

  .splash{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    
    overflow:hidden;


    width: 100%;
    

    justify-content: center;
    background-image: url(${require('./../../../static/images/splash-logo.svg')});
    background-position: center center;
    background-color:  ${({ theme }) => theme.colors.brand.primary};
    transition: background-color 0.3s;
    background-repeat: no-repeat;
    
    
    &>div {
      opacity:0;
    }

    &.splash-loaded
    {
      overflow:visible;
      background:  transparent;
      position: relative;
      &>div{
        opacity:1;
      }
    }
  }
    
`

export const GlobalStyles: React.FC = ({ children }) => {
  const theme = useTheme()
  return (
    <>
      <Styles theme={theme} />
      {children}
    </>
  )
}
