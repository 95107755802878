import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

export const Wrapper = styled.div`
  padding: 0 ${({ theme }) => theme.paddings.main * 4}px;

  max-width: 1440px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 ${({ theme }) => theme.paddings.main * 2}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 0 ${({ theme }) => theme.paddings.main}px;
    min-width: 320px;
    width: 100%;
  }
`
