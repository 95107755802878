import React from 'react'

import { Link } from 'gatsby'

import { styled } from 'src/ui/theme'
import { HBox, VBox, Row } from 'src/ui/layout'
import { Label } from 'src/ui/atoms'
import { Label as LabelText, Header, Body } from 'src/ui/typography'

const Container = styled.div`
  padding: 8px 0;
`

const Region = styled(LabelText)`
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
`

const Title = styled.div`
  a {
    text-decoration: none;

    color: ${({ theme }) => theme.colors.black.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.brand.primary};
    }
  }
`

type Props = {
  title: string
  regions: string[]
  tags: string[]
  link: string
}

export const Vacancy: React.FC<Props> = ({
  title,
  regions,
  tags,
  link,
  children,
}) => (
  <Container>
    {regions.length > 0 ? (
      <Region weight="bold">{regions.join(', ')}</Region>
    ) : null}

    <HBox height={8} />
    <Title>
      <Header size="h4" as="h2">
        <Link to={link}>{title}</Link>
      </Header>
    </Title>
    <HBox height={8} />
    <Body>{children}</Body>
    <HBox />
    <Row>
      {tags.map((tag, index) => (
        <React.Fragment key={index}>
          <Label>{tag}</Label>
          <VBox width={8} />
        </React.Fragment>
      ))}
    </Row>
  </Container>
)
