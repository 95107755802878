import { DashedSquare } from './dashed-square'
import { DashedSquareXL } from './dashed-square-xl'

import { Human } from './human'
import { SquaresArray } from './squares-array'
import { Peoples } from './peoples'

export const Adornments = {
  DashedSquare,
  DashedSquareXL,
  Human,
  SquaresArray,
  Peoples,
}
