import * as React from 'react'

export const Human: React.FC = () => (
  <svg width={220} height={262} viewBox="0 0 220 262" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.544 51.61c3.281-3.851 5.501-8.57 5.13-13.858-1.069-15.237-22.278-11.885-26.404-4.195-4.125 7.69-3.635 27.196 1.697 28.57 2.126.549 6.656-.794 11.267-3.573l-2.894 20.473h17.087l-5.883-27.417z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.01 24.694c2.252-.369 8.316-.623 9.434-.623.715 0 1.489.164 1.912-.493.347-.537.22-1.446.323-2.07.12-.723.291-1.438.464-2.152.491-2.03 1.233-3.96 2.272-5.773 1.95-3.4 4.806-6.065 8.411-7.615 3.982-1.711 8.487-2.103 12.77-1.756 4.583.371 8.732 1.884 13.059 3.308 4.124 1.357 8.443 1.89 12.756 1.228 4.345-.667 7.628-3.022 11.294-5.258 3.752-2.288 8.04-3.785 12.475-3.44 4.141.32 7.957 2.03 11.381 4.314 3.204 2.137 5.847 4.708 7.339 8.326 1.605 3.892 1.604 8.165-1.036 11.604-3.826 4.986-10.93 6.276-16.84 6.148a32.18 32.18 0 01-10.004-1.834c-4.304-1.514-8.023-4.08-12.05-6.161-1.89-.977-3.843-1.78-5.89-2.358-1.987-.56-3.944-.818-5.743.382-1.903 1.27-3.608 2.517-5.785 3.312-2.088.762-4.301 1.189-6.526 1.172-4.329-.034-8.596-1.383-12.801-2.286-3.429-.737-7.097-1.471-10.593-.763-1.459.296-3.589.906-4.116 2.422 8.867 1.607 12.349 12.573 9.85 20.623-1.349 4.345-9.459 9.424-12.613 7.14-1.997-1.447-.785-3.347 0-4.601 1.503-2.402 2.018-5.487-.764-7.16-2.399-1.441-4.921 1.983-4.969 1.98-.659-.052-6.357-3.275-10.282-7.977-3.873.23-4.46-2.323-3.536-4.412.925-2.088 6.697-4.716 9.808-5.227z"
      fill="#191847"
    />
    <path
      d="M139.546 262c-30.277 0-54.821-23.428-54.821-52.328 0-28.901 24.544-52.329 54.821-52.329 30.276 0 54.82 23.428 54.82 52.329 0 28.9-24.544 52.328-54.82 52.328z"
      fill="#C5CFD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.95 253.927c9.121-20.19 15.718-37.771 18.366-47.046 4.534-15.882 7.984-29.857 8.855-35.076 2.08-12.446-15.991-13.171-18.38-6.985-3.615 9.361-9.926 40.119-17.51 86.577l8.668 2.53zm105.811-63.362c-8.37-3.308-37.679-13.661-51.816-16.59-4.062-.842-7.999-1.624-11.7-2.33-11.003-2.101-16.703 17.748-6.107 19.273 26.39 3.798 63.803 7.721 67.005 8.161 4.487.617 7.878-6.435 2.618-8.514z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.491 166.183l45.272-23.046v-5.017h-5.384c-43.798 11.161-66.179 18.313-67.142 21.457l-.029.102-.065.087c-8.68 11.937-13.878 70.216-15.584 74.579l16.632 2.482c6.363-23.593 26.718-40.029 26.178-68.286a9.254 9.254 0 00.122-2.358z"
      fill="#1F28CF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.001 179.87c4.143-.83 7.957-1.57 11.221-2.142 21.084-3.698 27.232-14.984 23.597-39.608h-38.846c-6.346 1.823-39.323 15.652-56.433 23.008-11.325 4.868-7.755 22.418-1.342 26.985.035.281.15.471.356.556 34.497 14.298 61.788 6.796 70.904 9.193l4.217-13.302-13.674-4.69z"
      fill="#2B44FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.433 188.584c2.346.3 4.018.699 5.016 1.198 1.214.607 2.895 1.682 5.044 3.226l-16.935 35.599c-3.701-.706-5.208-2.121-4.52-4.244a203.183 203.183 0 001.658-5.319l.004-20.48c0-.394.33-.691.737-.663l.021.001 3.258.313c1.796-1.386 2.968-2.656 3.517-3.81.445-.937.657-2.433.636-4.488-.009-.789.644-1.39 1.459-1.342l.105.009zM40.101 250.183c.797 2.127 1.195 3.718 1.195 4.773 0 1.284-.214 3.157-.642 5.621H.5c-1.044-3.45-.442-5.313 1.807-5.588 2.248-.275 4.116-.523 5.603-.745l18.664-8.32a.712.712 0 01.948.379l1.2 2.91c2.08.971 3.772 1.456 5.073 1.456 1.058 0 2.518-.427 4.38-1.281a1.425 1.425 0 011.927.795z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.623 118.624l-25.798-11.329c-4.519-4.503-8.883-7.926-13.091-10.268-1.259-.4-3.409-.522-1.115 2.829 2.294 3.35 4.556 6.977 3.64 8.152-.917 1.174-3.572-.122-4.621 1.787-.7 1.273 3.776 2.796 13.427 4.569l19.32 14.813 8.237-10.553zm104.295 3.121l-14.589 2.894c14.672 27.343 22.54 41.835 23.605 43.476 2.395 3.691.16 10.456-.665 14.019 5.179 1.205 3.137-6.59 9.691-4.963 5.983 1.486 11.055 5.299 16.634.157.686-.633 1.188-2.609-1.151-3.622-5.826-2.525-14.433-7.253-15.828-9.089-1.904-2.503-7.802-16.794-17.697-42.872z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.678 67.16l-6.157-1.056c-13.409 13.008-18.881 44.258-27.817 48.394-8.45 3.911-20.426.127-34.662-4.983l-4.36 9.741c12.935 13.994 40.492 25.817 51.662 19.067 17.858-10.791 21.981-49.333 21.334-71.163z"
      fill="#DB2721"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.514 144.527H95.041c-2.49 0-2.02-3.594-1.66-5.408 4.152-20.932 20.363-43.495 20.363-73.291l15.713-2.464c12.996 20.902 17.49 46.636 20.057 81.163z"
      fill="#DDE3E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.947 123.334c11.105 20.031 20.439 34.452 28.002 43.263l11.418-2.236c-7.355-45.614-28.654-79.28-63.897-100.997h-6.221c-14.106 48.083-17.994 80.121-11.666 96.114h49.034c-.776-11.702-3.263-24.078-6.67-36.144z"
      fill="#FF4133"
    />
  </svg>
)
