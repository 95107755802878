import React, { ReactNode } from 'react'

import { styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

import { Body } from './body'

const lineHeights: {
  [key: string]: number
} = {
  h1: 88,
  h2: 58,
  h3: 52,
  h4: 29,
  h5: 22,
}

const fontSize: {
  [key: string]: number
} = {
  h1: 72,
  h2: 48,
  h3: 40,
  h4: 24,
  h5: 18,
}

const Styles = styled.div`
  font-size: 16px;
  line-height: 29px;
  max-width: 842px;

  p {
    & + p {
      margin-top: 18px;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.fonts.headers};
    color: ${({ theme, color }) =>
      color ? color : theme.colors.black.primary};
    letter-spacing: 0.02em;

    margin: 40px 0 24px 0;
  }

  h2 {
    font-size: ${() => fontSize['h2']}px;
    line-height: ${() => lineHeights['h2']}px;
  }
  h3 {
    font-size: ${() => fontSize['h3']}px;
    line-height: ${() => lineHeights['h3']}px;
  }
  h4 {
    font-size: ${() => fontSize['h4']}px;
    line-height: ${() => lineHeights['h4']}px;
  }
  h5 {
    font-size: ${() => fontSize['h5']}px;
    line-height: ${() => lineHeights['h5']}px;
  }

  strong,
  b {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style-position: outside;
    padding-left: 8px;

    li {
      display: block;
      position: relative;
      padding-left: 16px;
    }

    li::before {
      content: '•';
      position: absolute;
      left: 0;
    }

    ul li {
      padding-left: 17px;
    }

    ul li::before {
      content: '−';
    }
  }

  ol {
    counter-reset: counter;
    li {
      counter-increment: counter;

      &::before {
        content: counter(counter) '. ';
      }

      & + li,
      ul {
        margin-top: 24px;
      }
    }
  }

  blockquote {
    padding-left: 24px;
    border-left: 4px solid ${({ theme }) => theme.colors.brand.secondary};
    margin: 18px 0;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    h2,
    h3,
    h4,
    h5 {
      margin: 40px 0 16px 0;
    }

    h2 {
      font-size: ${() => fontSize['h4']}px;
      line-height: ${() => lineHeights['h4']}px;
    }
    h3 {
      font-size: ${() => fontSize['h5']}px;
      line-height: ${() => lineHeights['h5']}px;
    }
    h4 {
      font-size: ${() => fontSize['h5']}px;
      line-height: ${() => lineHeights['h5']}px;
    }
    h5 {
      font-size: ${() => fontSize['h5']}px;
      line-height: ${() => lineHeights['h5']}px;
    }
  }
`

type Props = {
  children: ReactNode
}

export const Markdown = ({ children }: Props) => (
  <Body>
    <Styles>{children}</Styles>
  </Body>
)
