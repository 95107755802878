import * as React from 'react'

export const Backend = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42 9.333H14a7 7 0 00-7 7V21a7 7 0 007 7h28a7 7 0 007-7v-4.667a7 7 0 00-7-7zM42 28H14a7 7 0 00-7 7v4.667a7 7 0 007 7h28a7 7 0 007-7V35a7 7 0 00-7-7zM16.332 18.667v.023M16.332 37.333v.023"
        stroke="#858585"
        strokeWidth={3.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
