import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { Row, Wrapper } from 'src/ui/layout'
import { breakpoints } from 'src/ui/dimensions'
import { Logo, Touchable } from 'src/ui/atoms'

const Background = styled.div`
  background: ${({ theme }) => theme.colors.gray.headerBg};
`

const Container = styled(Row)`
  justify-content: space-between;
  align-items: center;
  height: 120px;

  @media (max-width: ${breakpoints.tablet}px) {
    height: 56px;
  }
`

type Props = {
  onLogoPress: () => void
}

export const Header: React.FC<Props> = ({ onLogoPress, children }) => {
  const theme = useTheme()
  return (
    <Background>
      <Wrapper>
        <Container>
          <Touchable
            onPress={onLogoPress}
            hoverColor={theme.colors.brand.primary}
          >
            <Logo />
          </Touchable>
          {children}
        </Container>
      </Wrapper>
    </Background>
  )
}
