import React from 'react'
import { useTheme } from 'src/ui/theme'

type Props = {
  color?: string
}

export const SocialBe = ({ color }: Props) => {
  const theme = useTheme()
  return (
    <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.317 10.725c-.034-.517-.209-.908-.517-1.175-.309-.267-.692-.4-1.15-.4-.484 0-.9.142-1.159.425-.274.283-.45.667-.516 1.15h3.342Zm1.95-.192c.066.342.066.834.066 1.475h-5.416c.041.75.291 1.267.783 1.567.292.192.65.283 1.075.283.441 0 .8-.116 1.058-.341.167-.125.3-.292.417-.517h1.983c-.05.45-.291.892-.733 1.35-.65.733-1.584 1.083-2.784 1.083a4.134 4.134 0 0 1-2.633-.916c-.733-.609-1.117-1.6-1.117-2.975 0-1.292.342-2.292 1.026-2.959.683-.691 1.566-1.033 2.658-1.033.641 0 1.225.117 1.742.35.516.233.95.592 1.283 1.1.308.442.5.95.591 1.533ZM7.982 12.225c0-.542-.225-.917-.658-1.117-.242-.108-.592-.166-1.042-.191H4.058V13.7H6.25c.45 0 .8-.058 1.05-.183.458-.225.683-.659.683-1.292ZM4.058 9.217H6.25c.45 0 .833-.084 1.1-.259.283-.166.417-.475.417-.908 0-.467-.184-.8-.55-.958-.325-.109-.734-.159-1.225-.159H4.058v2.284Zm5.709 1.633c.266.417.4.925.4 1.517 0 .633-.167 1.166-.459 1.658a3 3 0 0 1-.733.817 3.013 3.013 0 0 1-1.175.516c-.45.092-.933.142-1.458.142H1.667V5.125h5c1.274.025 2.166.375 2.691 1.108.317.442.475.967.475 1.584 0 .633-.158 1.141-.475 1.525a2.55 2.55 0 0 1-.792.591c.525.192.925.5 1.2.917Zm6.95-4.25h-4.175V5.558h4.175V6.6Z"
        fill={color ?? theme.colors.black.total}
      />
    </svg>
  )
}
