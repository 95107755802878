const url =
  process.env.GATSBY_DEVELOP === 'true'
    ? 'https://strapi.kode.ru'
    : 'https://strapi.kode.ru'

const GTM_ID =
  process.env.GATSBY_DEVELOP === 'true' ? 'GTM-MC56537' : 'GTM-P29C8JV'
module.exports = {
  api_endpoint: `${url}/graphql`,
  static_endpoint: `${url}`,
  gtm_id: GTM_ID,
}
