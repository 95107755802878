import React from 'react'

import { FluidObject } from 'gatsby-image'

import { styled, useTheme } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'

import { Header } from 'src/ui/typography'
import { Image } from 'src/ui/atoms'

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
  background: ${({ theme }) => theme.colors.brand.primary};
  mix-blend-mode: multiply;
`
const Description = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  transition: opacity 0.3s;
  opacity: 0;

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 8px;
  }
`

const Container = styled.div<{ height: number }>`
  position: relative;

  height: ${({ height }) => height}px;

  &:hover {
    ${Bg}, ${Description} {
      opacity: 1;
    }
  }
`

type Props = {
  fluid: FluidObject
  height: number
  description: string
  objectPosition?: string
}
export const ImageWithDescription: React.FC<Props> = ({
  fluid,
  height,
  description,
  objectPosition,
}) => {
  const theme = useTheme()
  return (
    <Container height={height}>
      <Image fluid={fluid} height={height} objectPosition={objectPosition} />
      <Bg />

      <Description>
        <Header color={theme.colors.white.primary} size="h5">
          {description}
        </Header>
      </Description>
    </Container>
  )
}
