import React from 'react'

type Props = {
  color?: string
}

export const Bullet = ({ color }: Props) => {
  return (
    <svg
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2 16.852C10.9867 16.852 10.784 16.7987 10.592 16.692C10.4107 16.5854 10.2667 16.4414 10.16 16.26C10.0533 16.0787 10 15.8654 10 15.62C10 15.396 10.0533 15.1934 10.16 15.012C10.2667 14.8307 10.4107 14.6867 10.592 14.58C10.784 14.4734 10.9867 14.42 11.2 14.42C11.4133 14.42 11.6107 14.4734 11.792 14.58C11.9733 14.6867 12.1173 14.8307 12.224 15.012C12.3413 15.1934 12.4 15.396 12.4 15.62C12.4 15.8654 12.3413 16.0787 12.224 16.26C12.1173 16.4414 11.9733 16.5854 11.792 16.692C11.6107 16.7987 11.4133 16.852 11.2 16.852Z"
        fill={color ?? '#04040C'}
      />
    </svg>
  )
}
