import React from 'react'

type Props = {
  color?: string
}

export const CommunicationSend: React.FC<Props> = ({ color }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.9987 32.0001L5.33203 2.66675L13.332 32.0001L5.33203 61.3334L63.9987 32.0001ZM47.924 29.9254L17.812 28.1579L13.616 12.7713L47.924 29.9254ZM17.771 35.9907L48.5419 33.7657L13.6155 51.2289L17.771 35.9907Z"
      fill={color ?? '#4F4FF4'}
    />
  </svg>
)
