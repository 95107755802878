import { styled } from 'src/ui/theme'

type Props = {
  width?: number
}

export const VBox = styled.div<Props>`
  width: ${({ theme, width }) => width || theme.paddings.main}px;
  flex: 0 0 ${({ theme, width }) => width || theme.paddings.main}px;
`
