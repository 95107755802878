import React from 'react'

type Props = {
  stroke: string
}

export const Loader: React.FC<Props> = ({ stroke }) => {
  return (
    <svg width={24} height={24} fill="none">
      <path d="M12 22.5A10.5 10.5 0 1122.5 12" stroke={stroke} strokeWidth={3} strokeLinecap="round">
        <animate
          attributeType="XML"
          attributeName="stroke-dashoffset"
          begin="0s"
          dur="2s"
          from="31.4"
          to="-31.4"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 12 12"
          to="360 12 12"
          begin="0s"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  )
}
